import React, {useState} from 'react';
import TextBox from "../primitive/TextBox.js";
import { AwesomeButton } from 'react-awesome-button';
import axios from 'axios';
import "react-awesome-button/dist/styles.css";


function CommandRich(props) {

const [command, setCommand] = useState("");
const [out, setOut] = useState("");

  /**
   * Makes an axios request.
   */
  const sendCommand = () => {

        const toSend = {
            //TODO: Pass in the values for the data. Follow the format the route expects!
            cmd : command,
        };

        let config = {
            headers: {
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*',
            }
        }
        console.log(command);
        console.log(toSend);

        //Install and import this!
        //TODO: Fill in 1) location for request 2) your data 3) configuration
        axios.post(
            props.address + "rich",
            toSend,
            config
        )
        .then(response => {
            console.log(response);
            console.log(response.data["output"])
            // setOut(response.data["output"]);
        })
        .catch(function (error) {
            console.log(error);

        });
    }

    return (
      <div className="Command-Box">
        <TextBox label={"Command Input"} change={setCommand} value={command} />
        <AwesomeButton type={"primary"} onPress={sendCommand}> RICH OUT - for "render"</AwesomeButton>
        <p>Output: in console</p>
      </div>
    );
}

export default CommandRich;
