import * as d3 from 'd3old'
import {useEffect, useState, useRef} from 'react'
import test from './archive/test2.json';
import Viewer from 'react-drag-n-zoom'
import {makeStyles} from "@material-ui/core";
import {getClusterLinksMap} from "../ClusterPane/ClusterRender";

function Dendrogram(props) {

    let pairedClusters = useRef([])

    const [nodeColor, setNodeColor] = useState({
        'stroke':'#606060',
        'fill': 'gray',
        'stroke-width': '2px'
    })

    function handleAnalyze() {
        console.log("Sensing data")
        //props.addClusterButton(props.currClicked.current[0])
        //sendRenderCluster(props.currClicked[0])
        console.log(props)

        getClusterLinksMap(props.address, props.id, props.currClicked.current[0])

        console.log("Sensing data")
        console.log(props.currClicked.current)
        console.log(props.currClicked.current[0])
        props.addClusterButton(props.currClicked.current[0])
        console.log("CUR CLICK: " + props.currClicked.current[0])

        //this should make a call to server to
    }

    useEffect(() => {

        let phylogram = {}

        let rightAngleDiagonal = function() {
            var projection = function(d) { return [d.y, d.x]; }

            var path = function(pathData) {
                return "M" + pathData[0] + ' ' + pathData[1] + " " + pathData[2];
            }

            function diagonal(diagonalPath, i) {
                var source = diagonalPath.source,
                    target = diagonalPath.target,
                    midpointX = (source.x + target.x) / 2,
                    midpointY = (source.y + target.y) / 2,
                    pathData = [source, {x: target.x, y: source.y}, target];
                pathData = pathData.map(projection);
                return path(pathData)
            }

            diagonal.projection = function(x) {
                if (!arguments.length) return projection;
                projection = x;
                return diagonal;
            };

            diagonal.path = function(x) {
                if (!arguments.length) return path;
                path = x;
                return diagonal;
            };

            return diagonal;
        }

        function scaleBranchLengths(nodes, w) {
            // Visit all nodes and adjust y pos width distance metric
            var visitPreOrder = function(root, callback) {
                callback(root)
                if (root.children) {
                    for (var i = root.children.length - 1; i >= 0; i--){
                        visitPreOrder(root.children[i], callback)
                    };
                }
            }
            visitPreOrder(nodes[0], function(node) {
                node.rootDist = (node.parent ? node.parent.rootDist : 0) + (node.length || 0)
            })
            var rootDists = nodes.map(function(n) { return n.rootDist; });
            var yscale = d3.scale.linear()
                .domain([0, 1.5 * d3.max(rootDists)])
                .range([0, w]);
            visitPreOrder(nodes[0], function(node) {
                node.y = yscale(node.rootDist)
            })
            return yscale
        }

        function findMaxRootDistances(nodes, w) {
            // Visit all nodes and adjust y pos width distance metric
            var visitPreOrder = function(root, callback) {
                callback(root)
                if (root.children) {
                    for (var i = root.children.length - 1; i >= 0; i--){
                        visitPreOrder(root.children[i], callback)
                    };
                }
            }
            visitPreOrder(nodes[0], function(node) {
                node.rootDist = (node.parent ? node.parent.rootDist : 0) + (node.length || 0)
            })
            var rootDists = nodes.map(function(n) { return n.rootDist; });

            return 1.5 * d3.max(rootDists)
        }

        let build = function(selector, nodes, options) {
            options = options || {}
            var w = options.width || d3.select(selector).style('width') || d3.select(selector).attr('width'),
                h = options.height || d3.select(selector).style('height') || d3.select(selector).attr('height'),
                w = parseInt(w),
                h = parseInt(h);

            var tree = options.tree || d3.layout.cluster()
                .size([h, w])
                .sort(function(node) { return node.children ? node.children.length : -1; })
                .children(options.children || function(node) {
                    return node.branchset
                });
            var diagonal = options.diagonal || rightAngleDiagonal();
            var vis = options.vis || d3.select(selector).append("svg:svg")
                .attr("width", w + 300)
                .attr("height", h + 30)
                .append("svg:g")
                .attr("transform", "translate(20, 20)");
            var nodes = tree(nodes);

            if (options.skipBranchLengthScaling) {
                var yscale = d3.scale().linear()
                    .domain([0, w])
                    .range([0, w]);
            } else {
                var yscale = scaleBranchLengths(nodes, w)
            }


            //OLD ONCLICK
            // function toggleColor(d, cir) {

            //     let currentColor = d3.select(cir).attr('fill');
            //     //console.log(d3.select(cir).attr('fill'))

            //     console.log("D: COLOR: " + d.name)

            //     //currentColor = currentColor == "gray" ? "yellow" : "gray";

            //     if (currentColor === "gray") {
            //         currentColor = "yellow"
            //         props.currClicked.current.push(d.name)
            //     } else if (currentColor === "yellow") {
            //         currentColor = "gray"
            //         let i = props.currClicked.current.indexOf(d.name)
            //         props.currClicked.current.splice(i, 1)
            //     }

            //     //console.log(currentColor)
            //     //console.log(cir)

            //     d3.select(cir).attr("fill", currentColor);
            //     console.log(props.currClicked.current)
            // }
            
            
            
          
            
            

            

            const sleep = (milliseconds) => {
              return new Promise(resolve => setTimeout(resolve, milliseconds))
            }

            let styleTreeNodes = function(vis) {

                function toggleHover(d, cir) {

                    d3.select(cir)
                        .attr("fill","gold")
                        .attr("r","6px")
                        .append("title")
                        .text(function(d) { return d.name; })






                    // d3.select(cir).attr("fill","gold")
                    // d3.select(cir).attr("r","6px")

                    // d3.select(cir).attr("text")
                    //     // .style("background-color", "blue")
                    //     .attr("dx", -6)
                    //     .attr("dy", 2)
                    //     .attr("text-anchor", 'end')
                    //     .attr('font-size', '6px')
                    //     .attr('fill', function(d) {
                    //         if (d.sig == "true") {
                    //             return "red"
                    //         } else {
                    //             return "black"
                    //         }
                    //     })
                    //     .text(function(d) { return d.name });
                    //
                    // vis.selectAll('g.inner.node').append("svg:text")
                    //     .style("background-color", "blue")
                    //     .attr("dx", -6)
                    //     .attr("dy", 2)
                    //     .attr("text-anchor", 'end')
                    //     .attr('font-size', '6px')
                    //     .attr('fill', function(d) {
                    //         if (d.sig == "true") {
                    //             return "red"
                    //         } else {
                    //             return "black"
                    //         }
                    //     })
                    //     .text(function(d) { return d.name });
                    // Tooltip
                    // .style("opacity", 1)
                    //console.log(Tooltip.html())
                  }
      
                  function hoverEnd(d,cir) {
                    d3.select(cir).attr("fill","gray")
                    d3.select(cir).attr("r","4.5px")
                    let circle_x = d3.event.pageX;
                    // console.log("INIT: " + circle_x)
                    // let circle_y = d.pageY;
                    sleep(5000).then(() => { //TODO: MIGHT WANT TO REMOVE
                      // let new_circle_x = d3.event.pageX;
                      // console.log("SLEPT: " + new_circle_x)
                      if (props.currClicked.current.includes(d.name)) {
                        Tooltip
                          .style("opacity",0)
                      }
                      
                    })
                    
                  }

                  //TODO: use to make tooltip more smooth (click outside of tooltip takes it down.)
                  // window.onclick = function(event){
                    
                  //   if(event.target.className.baseVal != undefined){
                  //     console.log(event.target.className)
                  //     // Tooltip
                  //     // .style("opacity",0)
                  //     }
                  //   }
                  
      
                  var mousemove = function(d) {
                    //console.log("MOVE OVER " + d.name)
                    // Tooltip
                      
                      // .style("left", (d3.event.pageX - 20) + "px")
                      // .style("top", (d3.event.pageY -50) + "px")
                  
                  }
                
                  //NEW ONCLICK
                function toggleTooltip(d,cir) {
                  console.log("CLICKED: " + d.name)
                  let alreadyClicked = props.currClicked.current.includes(d.name)
                  console.log(alreadyClicked)
                  props.currClicked.current = []
                  props.currClicked.current.unshift(d.name)
                  console.log("SIG: " + d.sig)

                  if (pairedClusters.current.length === 1) {
                      pairedClusters.current = [pairedClusters.current[0], d]
                      let val = pairedClusters.current[0]["name"] + "<=>" + pairedClusters.current[1]["name"]
                      //props.cIDChange(val)
                      props.onClickFxn(val)
                      pairedClusters.current = []
                      props.setStatus("")

                      setNodeColor({
                          'stroke':'#606060',
                          'fill': 'gray',
                          'stroke-width': '2px'
                      })

                      return;
                  }

                  // var color = 
                  // console.log(color)
                  let tt_info = Tooltip
                    .style("display", "block")
                    .style("opacity", 1)
                    .style("left", (d3.event.pageX - 20) + "px")
                    .style("top", (d3.event.pageY -50) + "px")
                    .html("<b>" + d.name)
                    .append("div")
                      .style("display", "flex-row")
                      .style("items", "center")

                    //   .style("color", (d["sig"] === "true") ? "red" : "black")
                    //   .html("p=" + parseFloat(d["pvalue"]).toFixed(6) + "<br/>")
                    // .append("button")
                    //   .html("ANALYSIS")
                  
                  tt_info.append("button")
                      .html("ANALYSIS")
                      .on("click", function(b) {
                        Tooltip.style("display", "none")
                        //props.cIDChange(d.name)
                          console.log("blah")
                          console.log(d.name)
                        props.onClickFxn(d.name)})
                  tt_info.append("div")
                  tt_info.append("button")
                      .html("PAIRWISE ANALYSIS \n ** Please select a second cluster.")
                      .style("width", "150px")
                      .on("click", (b) => {
                          props.setStatus(<b style={{color: "red", "fontSize":"18px"}}>Please Click One More Cluster</b>)
                          Tooltip.style("display", "none")
                          pairedClusters.current = [d]
                          setNodeColor({
                              'stroke': '#343333',
                              'fill': 'gray',
                              'stroke-width': '2px'
                          })
                          //build(selector, nodes, options)
                      })
                  // tt_info.append("div")
                  //     .html("Note: If choosing pairwise analysis, Please select a second cluster")
                  console.log(Tooltip.html())
                  // d3.select(cir)
                  //   .style("stroke","black")
                  //   .style("opacity",1)
                    
                }

                vis.selectAll('g.leaf.node')
                    .append("svg:circle")
                    .attr("transform", function(d) {
                        if (isNaN(yscale(d.x))){
                            return ""
                        }
                        return "translate(" + (yscale(rootDis) - d.y - 90) + " " + 0 + ")"; })
                    .attr("r", 4.5)
                    .attr('stroke',  nodeColor['stroke'])
                    .attr('fill', nodeColor['fill'])
                    .attr('stroke-width', nodeColor['stroke-width'])
                    .on('click', function(d) {toggleTooltip(d, this);})
                    .on('mouseout', function(d,i) {hoverEnd(d,this)})
                    .on('mouseover', function(d,i) {toggleHover(d,this)})
                    // .on("mousemove",mousemove);

                vis.selectAll('g.inner.node')
                    .append("svg:circle")
                    .attr("r", 4.5)
                    .attr('stroke',  nodeColor['stroke'])
                    .attr('fill', nodeColor['fill'])
                    .attr('stroke-width', nodeColor['stroke-width'])
                    .attr("id", "two")
                    .on('click', function(d) {toggleTooltip(d, this);})
                    .on('mouseout', function(d,i) {hoverEnd(d,this)})
                    .on('mouseover', function(d,i) {toggleHover(d,this)})
                    // .on("mousemove",mousemove);

                vis.selectAll('g.root.node')
                    .append('svg:circle')
                    .attr("r", 4.5)
                    //.attr('stroke', '#369')
                    .attr('stroke',  nodeColor['stroke'])
                    .attr('fill', nodeColor['fill'])
                    .attr('stroke-width', nodeColor['stroke-width'])
                    .on('click', function(d) {toggleTooltip(d, this);})
                    .on('mouseout', function(d,i) {hoverEnd(d,this)})
                    .on('mouseover', function(d,i) {toggleHover(d,this)})
                    // .on("mousemove",mousemove);

                var Tooltip = d3
                    .select("#outer_div")
                    .attr("class", "node")
                    .append("div")
                    .attr("class", "tooltip")
                    .style("background-color", "white")
                    .style("border", "solid")
                    .style("position","absolute")
                    .style("border-width", "2px")
                    .style("border-radius", "5px")
                    .style("padding", "5px")
                    .style("opacity", 0)
                    .style("display", "none")
                    // .on("click",function() {alert("hi")});
            }

            //console.log(yscale.ticks(10))

            let rootDis = findMaxRootDistances(nodes, w)

            vis.selectAll('g.leaf.node').append("svg:text")
                .attr("dx", 8)
                .attr("dy", 3)
                .attr("text-anchor", "start")
                .attr('font-family', 'Helvetica Neue, Helvetica, sans-serif')
                .attr('font-size', '10px')
                .attr('fill', 'black')
                .text(function(d) { return d.name });

            if (!options.skipTicks) {
                vis.selectAll('line')
                    .data(yscale.ticks(10))
                    .enter().append('svg:line')
                    .attr('y1', 0)
                    .attr('y2', h)
                    .attr('x1', yscale)
                    .attr('x2', yscale)
                    .attr("stroke", "#ddd");

                vis.selectAll("text.rule")
                    .data(yscale.ticks(10))
                    .enter().append("svg:text")
                    .attr("class", "rule")
                    .attr("x", yscale)
                    .attr("y", 0)
                    .attr("dy", -3)
                    .attr("text-anchor", "middle")
                    .attr('font-size', '8px')
                    .attr('fill', '#ccc')
                    .text(function(d) { return Math.round(d*100) / 100; });
            }

            var link = vis.selectAll("path.link")
                .data(tree.links(nodes))
                .enter().append("svg:path")
                .attr("class", "link")
                .attr("d", diagonal)
                .attr("fill", "none")
                .attr("stroke", function(d) {

                    //"#aaa"
                    //console.log(d)
                    if (d.source.group === "group1") {
                        //return "khaki"
                        return "#FFE87C"
                        //return "steelblue"
                    } else if (d.source.group === "group2") {
                        return "steelblue"
                        //return "indianred"
                    } else {
                        return "#aaa"
                    }

                })
                .attr("stroke-width", "4px");

            var node = vis.selectAll("g.node")
                .data(nodes)
                .enter().append("svg:g")
                .attr("class", function(n) {
                    if (n.children) {
                        if (n.depth == 0) {
                            return "root node " + n.name
                        } else {
                            return "inner node " + n.name
                        }
                    } else {
                        return "leaf node " + n.name
                    }
                })
                .attr("transform", function(d) { return "translate(" + d.y + "," + d.x + ")"; })

            if (!options.skipLabels) {

                // vis.selectAll('g.inner.node')
                //     .append("rect")
                //     .style("fill", "white")
                //     .style("opacity", 1)
                //     .attr("x", -20)
                //     .attr("y", -5)
                //     .attr("width", "20px")
                //     .attr("height", "10px");

                // vis.selectAll('g.inner.node').append("svg:text")
                //     .style("background-color", "blue")
                //     .attr("dx", -6)
                //     .attr("dy", 2)
                //     .attr("text-anchor", 'end')
                //     .attr('font-size', '6px')
                //     .attr('fill', function(d) {
                //         if (d.sig == "true") {
                //             return "red"
                //         } else {
                //             return "black"
                //         }
                //     })
                //     .text(function(d) { return d.name });


                //console.log(rootDis)

                vis.selectAll('g.leaf.node')
                    .append("svg:path")
                    .attr("class", "link test")
                    .attr("d", function(d) {
                        //console.log(rootDis)
                        //console.log(yscale(rootDis))
                        //console.log("d.y: " + d.y)
                        let new_val = yscale(rootDis) - d.y
                        //console.log("new_val: " + new_val)
                        //console.log(yscale.invert(new_val))
                        //console.log(yscale(new_val))
                        return 'M-2,0 H ' + (new_val - 95)})
                    .attr("fill", "none")
                    .attr("stroke-width", "4px")
                    //.attr("stroke-dasharray", "5,5")
                    .attr("stroke", function(d) {

                        //"#aaa"
                        //console.log(d)
                        if (d.group === "group1") {
                            //return "khaki"
                            return "#FFE87C"
                            //return "steelblue"
                        } else if (d.group === "group2") {
                            return "steelblue"
                            //return "indianred"
                        } else {
                            return "#aaa"
                        }

                    })
                    .attr("id", "three");


                vis.selectAll('g.leaf.node').append("svg:text")
                    .attr("dx", function(d) {
                        return 5 + yscale(rootDis) - d.y - 80
                    })
                    .attr("dy", 3)
                    .attr("text-anchor", "start")
                    .attr('font-family', 'Helvetica Neue, Helvetica, sans-serif')
                    .attr('font-size', '10px')
                    .attr('fill', 'black')
                    .text(function(d) { return d.name });

                vis.selectAll('g.inner.node')
                    .append("use")
                    .attr("xlink:href", "#two")

                

            }

            

            styleTreeNodes(vis)

            function sorter(a, b) {
                if (a.children != null && b.children == null) {
                    return 1
                } else if (a.children == null && b.children != null) {
                    return -1
                }

                //console.log("got here")
                //console.log(a)
                //console.log(b)
                return 1
            }

            vis.selectAll("g.node").sort(sorter)


            console.log(vis.node().getBoundingClientRect())

            return {tree: tree, vis: vis}
        }

        /*
        const getData=()=>{
            fetch(props.link
                ,{
                    headers : {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Access-Control-Allow-Origin':'*',
                        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS'
                    }
                }
            )
                .then(function(response){
                    console.log(response)
                    return response.json();
                })
                .then(function(myJson) {
                    console.log(myJson);
                });
        }
         */

        //let data = getData()

        //console.log(props.jsonLink)
        fetch(props.jsonLink)
            .then(res => res.json())
            .then(json => {
                //console.log(json)
                build('#pgraphs', json, {
                    width: 400,
                    height: 1500
                });

                })
            .catch(() => {
                //TODO: figure out how to handle this
                // console.log("ERROR GETTING DENDROGRAM")
                //ADDed by jen
                // props.onClickFxn("WESPE2006")
            })

    }, [nodeColor])

    return (
        <div id="outer_div" style={{"height":(props.hcalc) + "px"}} ref={props.dendrogramRef}>
        <Viewer
            width="600"
            height="1600"
            viewBox="0 0 600 1600"
        >
          <svg id="pgraphs"></svg>

        </Viewer>
    {/*<button onClick={handleAnalyze}>Analyze</button>*/}
        </div>
    );
}

export default Dendrogram;