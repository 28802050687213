import { AppBar, Box, Button, Typography, Icon, IconButton, makeStyles, createTheme, ThemeProvider, Toolbar, Tooltip, FormHelperText } from "@material-ui/core";
import React, {useState} from "react";
import axios from "axios";
import {useNavigate} from 'react-router-dom';
// import { orange } from "@material-ui/colors";
// import Clear from "./Clear";
// import { KEY_SIGNATURE_DATA, MELODY_UPPER_DATA, MODE_DATA, SPECIES_DATA } from "./data";
// import Dropdown from "./Dropdown";
// import Load from "./Load";
import filePath from "../../backendconnect/FilePath";

import { saveAs } from "file-saver";

export const Navigation = ({id, address, projectName, setShowHelp, configData, modalOpen}) => {

  const navigate = useNavigate();

  const [copyProjectToolTip, setCopyProjectToolTip] = useState("Copy a link to a copy of the current project.");

  var uID = Math.random().toString(36).substr(2, 8).toUpperCase();

  function createProjectCopy(address, ID) {
    const toSend = {};
    let configJSON = {
      headers: {
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*',
      }
    }
    return axios.post(
        address + "copyProject/" + ID,
        toSend,
        configJSON
    ).then(response => {
      //console.log(response.data["output"]);
  
      let newID = response.data["output"]
      console.log(newID)
      //let colonI = address.indexOf(":")
      //let addressDomain =
      copyTextToClipboard(window.location.origin +"/project" + "/" + newID)
    })
  }
  
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top' style={{backgroundColor: "#ffffff"}}>
      <div className='container' >
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          
          <a href='../../..'className='page-scroll' >
          <Tooltip title={<Box fontWeight={300} fontSize={14} style={{ color: "white"}}>Back to Landing Page</Box>}>
            <img src='../../proteinarium_2_logo.jpg' id='navbarimglink' className='navbar-brand page-scroll'  style={{padding: "0px 0px"}}  />
          </Tooltip>
          </a>
          
          {' '}
          <p style={{display: "inline-block", paddingTop: "4%", fontSize: "18px"}}>&nbsp;<b>|</b>&nbsp; {projectName}</p>
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            <li>
            <Tooltip title={<Box fontWeight={300} fontSize={14} style={{ color: "white" }}>Save your current session's config, ID, and files.</Box>}>
              <a href='#' className='page-scroll'
              onClick={() => { 
                filePath(address, id, "projectzip").then((foundLink) => {
                  //TODO: edge case handling? should never occur tho
  
                  try {saveAs(foundLink,foundLink.substring(foundLink.lastIndexOf("\\")+1)) }
                  catch { alert("PROJECT NOT DONE LOADING YET") }
  
                  const downloadTxtFile = () => {
  
                    let saveText = "";
                    //"Link To Website: " + address + id + "\n"
                    //saveText = saveText + "Data Expires on : " + "Insert Date" + "\n"
                    if (configData !== undefined) {
                      let keys = Object.keys(configData)
                      console.log(configData)
                      for (let i in keys) {
                        /*
                        if (keys[i] === "geneset1" || keys[i] === "geneset2") {
                          saveText = saveText + keys[i] + ": " + configData[keys[i]]["0"]["name"] + "\n"
                        } else {
                          saveText = saveText + keys[i] + ": " + configData[keys[i]] + "\n"
                        }
                        */
                        saveText = saveText + keys[i] + ": " + configData[keys[i]] + "\n"
                      }
                    } else {
                      //TODO cover error here
                      return
                    }
  
                    const element = document.createElement("a");
                    const file = new Blob([saveText], {
                      type: "text/plain"
                    });
                    element.href = URL.createObjectURL(file);
                    element.download = projectName + "_Config.txt";
                    document.body.appendChild(element);
                    element.click();
                  };
  
                  console.log("got here")
                  downloadTxtFile()
  
                })
  
              }}
              >
                <b>Save Session</b>
              </a>
              </Tooltip>
            </li>
            <li>
            <Tooltip title={<Box fontWeight={300} fontSize={14} style={{ color: "white" }}>{copyProjectToolTip}</Box>}>
              <a href='#'  className='page-scroll'
              onClick={() => createProjectCopy(address, id).then(() => {
                setCopyProjectToolTip("New Link Saved to Clipboard!")
              })} onMouseOut={() => {
                setCopyProjectToolTip("Copy a link to a copy of the current project.")
              }}>
                <b>Share Link</b>
              </a>
              </Tooltip>
            </li>
            <li>
            <Tooltip title={<Box fontWeight={300} fontSize={14} style={{ color: "white" }}>General tips on how to navigate the site and analysis.</Box>}>
              <a href='#' className='page-scroll' onClick={() => setShowHelp(true)}>
                  Help
              </a>
              </Tooltip>
            </li>
            
            

            <li>
            <Tooltip title={<Box fontWeight={300} fontSize={14} style={{ color: "white" }}>Create a new analysis session.</Box>}>
              <a className='page-scroll' 
              href="#"
              onClick={(e) => {
                console.log(window.location.pathname)
                navigate('/newProjectConfig', {replace: false, state: {"UsingOldGenesets": false}})

                // if (window.location.pathname.includes("/project")) {
                //   newContent = <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} gridGap={"10px"} margin={"auto"} width={"100%"} height={"100%"}>
                //       <Box display={"flex"} flexDirection={"row"} justifyContent={"center"} gridGap={"30px"}>
                //         <button onClick={() => {
                //           navigate('/newProjectConfig', {replace: false, state: {"UsingOldGenesets": true, "id":id, "data": configData}})
                //         }}>Use Previous Genesets</button>
                //         <button onClick={() => {navigate('/newProjectConfig', {replace: false, state: {"UsingOldGenesets": false}})}}>Use New Genesets</button>
                //       </Box>
                //       <div>
                //         <p>Geneset 1: <b>{configData["geneset1"]}</b></p>
                //         <p>Geneset 2: <b>{configData["geneset2"]}</b></p>
                //       </div>
                //     </Box>
                //   modalOpen(newContent)
                // } else if (window.location.pathname.includes("/newProject")) {
                //   //Navigate to new Project
                //   navigate('/newProjectConfig', {replace: false, state: {"UsingOldGenesets": false}})
                // }

              }}>
                New Session
              </a>
              </Tooltip>
            </li>

          </ul>
        </div>
      </div>
    </nav>
  )
}
