// FROM: NewProject.js
import '../SetupComponents/NewProject.css';
import {AwesomeButton} from "react-awesome-button";
import React, {useState, useCallback} from "react";
import GeneralConfigDichotomous from "./GeneralConfigDichotomous";

function NewProject(props) {
    let configDisplay = <div className="ConfigSetup">

        <div className={"ConfigBox"} style={{marginTop: 100}}>
            <GeneralConfigDichotomous
                address={props.address}
                idString={props.idString}
                setID={props.setID}
                setProjectName={props.setProjectName}
                setConfigData={props.setConfigData}
                useOldGenesets={props.useOldGenesets}
                previousID={props.previousID}
                previousConfig={props.previousConfig}
                setAnalysisType={props.setAnalysisType}
            />
        </div>

        <br></br>
        <br></br>
    </div>

    return (
        <div>
            {configDisplay}
        </div>
    );
}

export default NewProject;
