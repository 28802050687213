//import ScriptTag from 'react-script-tag';
//import NET from './vanta.net.min.js'
import { useState, useEffect, useRef } from 'react'
import NET from 'vanta/dist/vanta.net.min'


const imgstyle = {
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
  width: "100%"
};

export const Header = (props) => {

  const [vantaEffect, setVantaEffect] = useState(0)
  const vantaRef = useRef(null)
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(NET({
        el: vantaRef.current,
        color: 0xc8c0c1,
        backgroundColor: 0xffffff,
        height: "100%"
      }))
    }
    if (vantaEffect) document.querySelector('.vanta-canvas').style.height = "100%";
    return () => {
      if (vantaEffect) vantaEffect.destroy()
    }
  }, [vantaEffect])

  return (
    <header id='header' >
      <div className='intro' ref={vantaRef}>
        <div className='overlay' >
          <div className='container' >
            <div className='row'>
              <div className='col-md-8 col-md-offset-2 intro-text' style={{background: "rgb(196 196 196 / 50%)"}}>
                <img src="new_logo_option.png" style={imgstyle}/>
                {/* <h1 style={{textColor: "black"}}>
                  Proteinarium 2
                  <span></span>
                </h1> */}
                <p>Multi sample protein-protein interaction analysis and visualization tool</p>
                <a
                  href='/NewProjectConfig'
                  className='btn btn-custom btn-lg page-scroll'
                >
                  New Session
                </a>{' '}
                <a
                  // href='https://docs.google.com/document/d/1xtR5DrNvEvItuW8sJZtBDssiogHlPAHMzZNJmCfWFBo/edit?usp=sharing'
                  // target="_blank"
                  onClick={() => {alert("DOCUMENTATION COMING SOON")}}
                  className='btn btn-custom btn-lg page-scroll'
                >
                  Documentation
                </a>{' '}
                <a
                  href='https://www.sciencedirect.com/science/article/pii/S0888754320303050'
                  target="_blank"
                  className='btn btn-custom btn-lg'
                >
                  Publication
                </a>{' '}
              </div>
            </div>  
          </div>
        </div>
      </div>
    </header>
  )
}
