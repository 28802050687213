import {
  Box,
  createTheme,
  Link,
  makeStyles,
  ThemeProvider,
  Typography,
  Divider,
  Icon,
  IconButton
} from "@material-ui/core";
import React from "react";
// import GithubCorner from 'react-github-corner';

const theme = createTheme({
  palette: {
    primary: {
      main: "#141414"
    },
    secondary: {
      main: "#EEF0F2",
    },
  },
  typography: {
    fontFamily: [
      'Open Sans',
    ].join(','),
    fontSize: "1.5rem"
  },
})

const useStyles = makeStyles({
  section: {
    marginBottom: "8px",
  },
  paper: {
    overflowY: 'unset',
  },
  customizedButton: {
    width: 40,
    height: 40,
    position: 'absolute',
    left: '96%',
    top: '1%',
    backgroundColor: 'lightgray',
    color: 'gray',
  }
})

const About = (themeUsed, setShow) => {
  const classes = useStyles();
  return <ThemeProvider theme={theme}>
    <Box>
      <Box className={classes.section}>
        <Typography variant="h5" style={{fontSize: "2rem"}}><b>Proteinarium Web - </b> <Link href="https://www.sciencedirect.com/science/article/pii/S0888754320303050?via%3Dihub" target="_blank" rel="noopener">Genomics</Link> - <Link href="https://github.com/alperuzun/Proteinarium/" target="_blank" rel="noopener">Github</Link></Typography>
        <Divider></Divider> <br></br>
        <Typography variant="body1">
        Proteinarium is a multi-sample protein-protein interaction (PPI) tool to identify clusters of samples with shared networks.
    Proteinarium provides useful analysis and visualizations. The Dendrogram and layered PPI network.
    The dendrogram allows the user a global view of the distribution of samples into clusters.
    In Proteinarium any branch of the dendrogram can be displayed as a layered PPI network of the samples within the cluster.
        </Typography>
        {/* <Typography variant="body1">
          To get started, use the <Typography variant="button">load</Typography> button to load an example score in to play around with. Check out the <Typography variant="button">hotkeys</Typography> tab to see how to use the score editor. Remember, you can always bring up this welcome screen by clicking the <Typography variant="button">help</Typography> button. The alto clef is always the melody line, and the other clef is the countermelody line.
        </Typography> */}
      </Box>
      <Box className={classes.section}>
        <Typography variant="h6" style={{fontSize: "2rem"}}><b>How do I use this webapp?</b></Typography>
        <Typography variant="body1">
        N/A (In Development)
        </Typography>
        {/* <Typography variant="body1">
          If you need a refresher on basic music theory, this <Link href="https://musictheory.pugetsound.edu/mt21c/Pitch.html" target="_blank" rel="noopener">section</Link> of the same online textbook is the right place to be.
        </Typography> */}
      </Box>
      {/* <Box className={classes.section}>
        <Typography variant="h6">How was CounterPointer made?</Typography>
        <Typography variant="body1">
          CounterPointer was created as the final project for Brown University's <Link href="http://cs.brown.edu/courses/cs0320/" target="_blank" rel="noopener">CSCI 0320</Link> course by <Link href="https://github.com/alexander-ding" target="_blank" rel="noopener">Alex Ding</Link>, <Link href="https://github.com/0x85FB9C51" target="_blank" rel="noopener">En-Hua Holtz</Link>, <Link href="https://github.com/johnchung1010" target="_blank" rel="noopener">John Chung</Link>, and <Link href="https://github.com/obloomfield" target="_blank" rel="noopener">Orion Bloomfield</Link>.
        </Typography>
      </Box>
      <Box className={classes.section}>
        <Typography variant="h6">I love this ❤️! How can I show my appreciation?</Typography>
        <Typography variant="body1">
          Allow me to redirect your attention to the cat on the top right corner. Click on the cat to visit our GitHub repository, star the project, and help us make it better by opening issues and pull requests!
        </Typography>
      </Box> */}
      {/* <GithubCorner href="https://github.com/counter-pointer/counterpointer" /> */}
    </Box>
  </ThemeProvider>;
}

export default About;