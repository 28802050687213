import { Box, Container, makeStyles, Tooltip, Icon, Grid, Typography, createTheme, ThemeProvider, Button } from "@material-ui/core";
// import { createTheme } from "@mui/material/styles";
// import AutoAwesomeMotionIcon from '@material-ui/icons-material/AutoAwesomeMotion';
import React from "react";

// steelblue is a NICE COLOR

const useStylesCluster = makeStyles( bgColor => ({
  inline: {
    display: "inline",
    // color: "#FFFFFF"
  },
  button: {
    border: '2px solid black',
    backgroundColor: "white", //work on parameterizing later
    marginBottom: 10,
    marginLeft: 2,
    marginRight: 2,
    justifyContent: "left",
    padding: "0px",
    color: "rgba(0,0,0,0.87)",
    fontSize: "0.875 rem",
    minWidth: "110%",
    fontFamily: "Oswald, Oswald Light, Open Sans",
    lineHeight: "1.75",
    textTransform: "uppercase",
    borderRadius: "4px",
    transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    '&:hover': {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
      //transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    }
  },
  innericon: {
    backgroundColor: "indianred", //work on parameterizing later
    borderRadius: "0px 0px 15px 0px"
  },
  clicker: {
    transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    '&:hover': {
      //transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    }
  }
}))

/*
const useStyles = makeStyles( bgColor => ({
  inline: {
    display: "inline",
    // color: "#FFFFFF"
  },
  button: {
    border: '1.5px solid',
    backgroundColor: "white", //work on parameterizing later
    margin: 10,
    marginTop: 40,
  },
  innericon: {
    border: '1.5px solid',
    borderRadius: 3,
    backgroundColor: "white", //work on parameterizing later
  }
}))
 */

const theme = createTheme({
  palette: {
    primary: {
      main: "#EEF0F2"
    },
    secondary: {
      main: "#EEF0F2",
    },
  },
  typography: {
    fontFamily: [
      'Oswald',
      'Oswald Light',
      'Open Sans'
    ].join(','),
  },
})
//TODO: legitimate fix for cap, not the silly margin stuff

function ClusterButton({clusterNum, bgColor, iconUsed, onPress, onRemove, i, buttonList}) {

  let classes = useStylesCluster(bgColor);

  const useStyles = makeStyles({
    root: {}
  });

  const noClasses = useStyles()

  if (!clusterNum) {}
  const clusterID = clusterNum.toUpperCase();

  const body = <div className={classes.button}>
          <Grid item xs={6}>
            <div style={{display: "flex", gap:"18%"}}>
              <a onClick={(e) => {onRemove(clusterNum)}} style={{cursor: "pointer", color: "rgba(0,0,0,0.87)"}} className={classes.clicker}>
              <Box className={classes.innericon}>
                <Icon i={i}>{iconUsed}</Icon>
              </Box>
            </a>
            <a onClick={onPress} style={{cursor: "pointer", color: "rgba(0,0,0,0.87)"}} className={classes.clicker}>
              <div className={classes.inline} fontWeight={400} fontSize={20} fontFamily={'Oswald'} letterSpacing={0.5} >
                  {clusterNum}
              </div>
            </a>
            </div>
          </Grid>
        </div>
  return (
    <ThemeProvider theme={theme}>
      {/* <Box display={"flex"} paddingTop={3}>  */}
      {/* {
        clusterNum == "NEW" 
          ? <Tooltip title={<Box fontWeight={300} fontSize={14} style={{ color: "white" }}>Click and drag on the dendrogram to make new cluster analyses</Box>}>
            {body}
            </Tooltip>
          : {body}
      } */}
        {body}
      {/* </Box> */}
    </ThemeProvider>
  );
}

export default ClusterButton;
