function TextBox(props) {
  return (
    <input 
      type={"text"}
      id={props.id}
      ref={props.ref}
      style={props.style}
      label={props.label}
      onChange={e => props.change(e.target.value)}
      //onChange={event => props.change(event.target.value)}
    ></input>
  );
}

export default TextBox;
