import { Box, Container, makeStyles, Typography, Icon, createTheme, ThemeProvider } from "@material-ui/core";
// import { createTheme } from "@mui/material/styles";
import React from "react";

import Dendrogram from "./Dendrogram";
import {useEffect, useState, useRef} from 'react'
import D3TreeTest from "./archive/D3TreeTest";
import dendrogramImage from '../archive/example_dendrogram.png'
import ClusterRender from "../ClusterPane/ClusterRender";
import key from "../ClusterPane/key.png";

import filePath from "../backendconnect/FilePath";

import { saveAs } from "file-saver";
import html2canvas from 'html2canvas';
import axios from "axios";


const useStyles = makeStyles(height => ({
  box: boxHeight => ({
    border: "2px solid black",
    paddingBottom: 0,
    marginTop: 30,
    marginLeft: 10,
    marginRight: 10,
    width: "100%",
    borderRadius: 8
  }),
  title: {
    verticalAlign: "top"
  },
  cap: {
    backgroundColor: "#C4C4C4",
    marginLeft: "-2%",
    marginRight: "-2%",
    border: "1px solid black",
    borderRadius: 4
  }
}))

const theme = createTheme({
  palette: {
    primary: {
      main: "#EEF0F2"
    },
    secondary: {
      main: "#EEF0F2",
    },
  },
  typography: {
    fontFamily: [
      'Oswald',
      'Oswald Light',
      'Open Sans'
    ].join(','),
  },
})
//TODO: legitimate fix for cap, not the silly margin stuff

function DendrogramBox(props) {

  let boxRef = useRef();
  let hcalc = 1000;

  let dendrogramRef = useRef();
  let dendrogramSize = useRef({"width": 700, "height":1530})

  console.log(props)

  useEffect(() => {
    console.log(boxRef.current.clientHeigh)
    hcalc = boxRef.current.clientHeight;
  }, );

  console.log(props.jsonLink)

  const [status, setStatus] = useState("");

  let group1alias = props.configData.group1alias;
  let group2alias = props.configData.group2alias;
  if (group1alias !== "group1") {
      group1alias = group1alias + " (group1)"
  }
    if (group2alias !== "group2") {
        group2alias = group2alias + " (group2)"
    }

  let DenoKey = <Box display={"flex"} flexDirection={"row"} alignSelf={"center"}>
    <div style={clusterDiagramKeyStyle("group1")}>{group1alias}</div>
      {group2alias !== `undefined (group2)` &&  <div style={clusterDiagramKeyStyle("group2")}>{group2alias}</div>}
  </Box>

  function clusterDiagramKeyStyle(group) {

    let gradient = ""
    if (group === "group1") {
      gradient = "linear-gradient(to right, rgba(255,200,0,0.2), rgba(255,200,0,0.8))" //yellow
    } else if (group === "group2") {
      gradient = "linear-gradient(to right, rgba(0,0,255,0.2), rgba(0,0,255,0.8))" //blue
    } else if (group === "both") {
      gradient = "linear-gradient(to right, rgba(0,255,0,0.2), rgba(0,255,0,0.8))" //green
    } else if (group === "imputed") {
      gradient = "linear-gradient(to right, rgba(255,0,0,0.2), rgba(255,0,0,0.8))" //red
    }

    let styles = {"backgroundImage":gradient, "paddingLeft":"5px", "paddingRight":"5px", "height":"25px", "margin": "10px", "border" : "thin solid"}

    return styles
  }

  const classes = useStyles(props.boxHeight);
  const upperTitle = props.boxTitle.toUpperCase();
  return (
    <ThemeProvider theme={theme}>
      <Container id="box" ref={boxRef} className={classes.box} >
        <Box id="cap" className={classes.cap}>
          <Box fontWeight={300} fontSize={20} fontFamily={'Oswald'} letterSpacing={2.5} id="title" className={classes.title} textAlign={"center"}>
            
             <b>{upperTitle}</b> 
             <button onClick={() => {

                 //get svg element.
                 var svg = document.getElementById("pgraphs");
                 //get svg source.
                 var serializer = new XMLSerializer();
                 var source = serializer.serializeToString(svg);
                 //add name spaces.
                 if(!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)){
                     source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
                 }
                 if(!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)){
                     source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
                 }

                 // TODO: Find a better way to hardcode this
                 source = "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"700\" height=\"1530\">" + source + "</svg>"
                 //add xml declaration
                 //source = '<?xml version="1.0" standalone="no"?>\r\n' + source;
                 //convert svg source to URI data scheme.
                 var url = "data:image/svg+xml;charset=utf-8,"+encodeURIComponent(source);
                 //set url value to a element's href attribute.
                 //document.getElementById("link").href = url;

                 const link = document.createElement('a');
                 link.href = url;
                 link.download = `dendrogram.jpg`;

                 document.body.appendChild(link);
                 //you can download svg file by right click menu.

                 let formData1 = new FormData();
                 fetch(url).then(r => {
                     r.blob().then((blob) => {
                         formData1.append("dendrogramSVG", blob);

                         let configFILE = {
                             headers: {
                                 "Content-Type": "multipart/form-data",
                                 'Access-Control-Allow-Origin': '*',
                             }
                         }

                         let res1 = axios.post(
                             props.address + "dendrogramSave/" + props.id,
                             formData1,
                             configFILE
                         ).then(() => {
                             filePath(props.address, props.id, "dendrozip").then((foundLink) => {
                                 //TODO: edge case handling? should never occdendrour tho
                                 try {
                                     console.log("GOT HERE - log from dendrogrambox, 171")
                                     saveAs(foundLink,foundLink.substring(foundLink.lastIndexOf("\\")+1))

                                 }
                                 catch { alert("DENDROGRAM NOT LOADED YET") }
                             })
                         })
                     })
                 });
             }
            } style={{"textAlign": "left"}}><Icon>{"save"}</Icon></button>
          </Box>
        </Box>
        {props.content}
        {/*{status}*/}
        {props.configData !== undefined ? <div style={{"textAlign": "center"}}>{"Interactome Database:"} <b style={{textTransform: "uppercase"}}>{props.configData.db}</b></div> : <span></span>}
        {props.boxTitle == "Dendrogram"
        ? <Box display={"flex"} flexDirection={"column"}>
              {DenoKey}
              {/*  this is shown! */}
              <Dendrogram
                  setPaired={props.setPaired}
                  setStatus={setStatus}
                  currClicked={props.currClicked}
                  addClusterButton={props.addClusterButton}
                  address={props.address} id={props.id}
                  hcalc={hcalc} jsonLink={props.jsonLink}
                  onClickFxn={props.onClickFxn}
                  dendrogramRef={dendrogramRef}/>

            </Box>
        : <div><ClusterRender address={props.address} id={props.id}/></div> }

        {/* // ? <img src={dendrogramImage} style={{width: "70%", height: "auto"}}></img>
        // : <p></p>} */}
      </Container>
    </ThemeProvider>
  );
}

export default DendrogramBox;
