// NOTE: TO BE ARCHIVED -- SPLIT INTO SINGLE + MULTI SAMPLE NEW PROJ
import '../SetupComponents/NewProject.css';
import TextBox from "../archive/primitive/TextBox";
import {AwesomeButton} from "react-awesome-button";
import React, {useState, useCallback} from "react";
import {useNavigate} from 'react-router-dom';
import GeneralConfig from "./GeneralConfig";
import AnalysisConfig from "./archive/AnalysisConfig";
import {Link} from "react-router-dom";
import {Form} from "easy-react-form";
import {sendCommand} from "./archive/sendConfig.js"
import {sendZipCommand} from "./sendZipConfig.js"
import DendrogramLoad, {getDenoLink} from "../DendrogramPane/DendrogramLoad";
import ClusterRender from "../ClusterPane/ClusterRender";

function NewProject(props) {



    let configDisplay = <div className="ConfigSetup">

            {/* <AwesomeButton onPress={() => props.bool(false)}>Go to Demo</AwesomeButton> */}

            <div className={"ConfigBox"}>
                <GeneralConfig address={props.address} idString={props.idString} setID={props.setID} setProjectName={props.setProjectName} setConfigData={props.setConfigData} useOldGenesets={props.useOldGenesets} previousID={props.previousID} previousConfig={props.previousConfig}></GeneralConfig>

            </div>

            <br></br>
            <br></br>

            <div className={"OptionButtons"}>
                <Link to="/"><AwesomeButton className={"aButtonLeft"}>Cancel</AwesomeButton></Link>
                <br></br>

            </div>
        </div>

    return (
        <div>
            {configDisplay}
        </div>
    );
}

export default NewProject;
