import logo from '../../Resources/uzun.png';
import '../../archive/ConfigSetup.css';
import Command from '../../archive/backendconnect/Command.js'
import CommandRich from '../../archive/backendconnect/CommandRich.js'
import axios from "axios";
import TextBox from "../../archive/primitive/TextBox";
import {AwesomeButton} from "react-awesome-button";
import React, {useState} from "react";
import ShowMoreText from "react-show-more-text";
import { Form, Field } from 'easy-react-form'
import GeneralConfig from "../GeneralConfig";
import AnalysisConfig from "./AnalysisConfig";
import {Link} from "react-router-dom";

function ConfigSetup(props) {

  const [command, setCommand] = useState("");

  /**
   * Makes an axios request.
   */
  const sendCommand = () => {

      let formData1 = new FormData();
      let imagefile1 = document.querySelector('#group1GeneSetFile')
      formData1.append("group1GeneSetFile", imagefile1.files[0]);

      let formData2 = new FormData();
      let imagefile2 = document.querySelector('#group2GeneSetFile')
      formData2.append("group2GeneSetFile", imagefile2.files[0]);

    const toSend = {
      config : "maxPathLength=4 bootstrappingRounds=0 maxVerticesToRender=50 repulsionConstant=1.2 projectName=SIMDATA_mpl3",
        needGeneset2: "n"
    };

    let configJSON = {
          headers: {
              "Content-Type": "application/json",
              'Access-Control-Allow-Origin': '*',
          }
    }

    let configFILE = {
      headers: {
        "Content-Type": "multipart/form-data",
        'Access-Control-Allow-Origin': '*',
      }
    }

    let ID = ""

      axios.post(
          props.address + "id",
          {},
          configJSON
      ).then(response => {
          console.log(response.data["ID"]);
          ID = response.data["ID"]
          //setOut(response.data["ID"]);
          sendData(ID)

      }).catch(function (error) {
          console.log(error);
      });

    console.log(imagefile1.value !== "")
      console.log(imagefile2.value !== "")


      function sendData(ID) {
          //Install and import this!
          //TODO: Fill in 1) location for request 2) your data 3) configuration
          console.log(imagefile1.value)
          console.log("|" + imagefile2.value + "|")
          console.log(props.address)
          if (imagefile1.value !== "") {
              axios.post(
                  props.address + "gfile/" + ID,
                  formData1,
                  configFILE
              ).then(response => {
                  console.log(response);
                  //setOut(response.data["output"]);
              }).catch(function (error) {
                  console.log(error);
              });
          }

          if (imagefile2.value !== "") {
              axios.post(
                  props.address + "gfile/" + ID,
                  formData2,
                  configFILE
              ).then(response => {
                  console.log(response);
                  //setOut(response.data["output"]);
              }).catch(function (error) {
                  console.log(error);
              });
          }

          axios.post(
              props.address + "config/" + ID,
              toSend,
              configJSON
          ).then(response => {
              console.log(response);
              //setOut(response.data["output"]);
          }).catch(function (error) {
              console.log(error);
          });
      }


  }

    function executeOnClick(isExpanded) {
        console.log(isExpanded);
    }

  return (
      <div className="ConfigSetup">

          <Link to="/">Home</Link>
          <br/>

          <div className={"ConfigBox"}>
              <GeneralConfig setProjectName={props.setProjectName}/>
          </div>

          <div className={"ConfigBox"}>
              <AnalysisConfig/>
          </div>

          <br/>
          <TextBox label={"Command Input"} change={setCommand} value={command} />
          <AwesomeButton type={"primary"} onPress={sendCommand}>Enter Config</AwesomeButton>
      </div>
  );
}

export default ConfigSetup;
