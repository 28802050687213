import { AppBar, Box, Button, Typography, Icon, IconButton, makeStyles, createTheme, ThemeProvider, Toolbar, Tooltip, FormHelperText } from "@material-ui/core";
// import { createTheme } from "@mui/material/styles";
import React, {useState} from "react";
import axios from "axios";
import {useNavigate} from 'react-router-dom';
// import { orange } from "@material-ui/colors";
// import Clear from "./Clear";
// import { KEY_SIGNATURE_DATA, MELODY_UPPER_DATA, MODE_DATA, SPECIES_DATA } from "./data";
// import Dropdown from "./Dropdown";
// import Load from "./Load";
import filePath from "../../backendconnect/FilePath";

import { saveAs } from "file-saver";

// import protLogo from '../../Resources/proteinarium_logo.jpeg'

const useStyles = makeStyles({
  right: {
    marginLeft: "auto",
    // display: "flex",
    // justifyContent: "space-between",
    // maxWidth: "400px",
    // margin: "0 auto",
    // padding: "10px 0"
  },
  inline: {
    display: "inline",
    // color: "#FFFFFF"
  },
  button: {
    border: '1.5px solid'
  }
});

// const theme = createTheme();

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFFFFF"
    },
    secondary: {
      main: "#EEF0F2",
    },
  },
  typography: {
    fontFamily: [
      'Oswald',
      'Oswald Light',
      'Open Sans'
    ].join(','),
  },
})

function createProjectCopy(address, ID) {
  const toSend = {};
  let configJSON = {
    headers: {
      "Content-Type": "application/json",
      'Access-Control-Allow-Origin': '*',
    }
  }
  return axios.post(
      address + "copyProject/" + ID,
      toSend,
      configJSON
  ).then(response => {
    //console.log(response.data["output"]);

    let newID = response.data["output"]
    console.log(newID)
    //let colonI = address.indexOf(":")
    //let addressDomain =
    copyTextToClipboard(window.location.origin +"/project" + "/" + newID)
  })
}

async function copyTextToClipboard(text) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
}

const Navbar = ({id, address, projectName, setShowHelp, configData, modalOpen}) => {

const navigate = useNavigate();

const classes = useStyles();

const [copyProjectToolTip, setCopyProjectToolTip] = useState("Copy a link to a copy of the current project.");

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

//const [openCopyProjectToolTip, setOpenCopyProjectToolTip] = useState(false)

var uID = Math.random().toString(36).substr(2, 8).toUpperCase();

  return (
  <ThemeProvider theme={theme}>
    <AppBar position="static">
      <Toolbar>
        <Box>
          {/* <Tooltip title="Which species counterpoint to write"> */}
            <Box className={classes.inline} fontWeight={700} fontSize={40} fontFamily={'Oswald'} letterSpacing={2.5}>
              {/* <b>PROTEINARIUM 2</b> */}
              <a href='#header'className='page-scroll' ><img src='../proteinarium_2_logo.jpg' id='navbarimglink' className='navbar-brand page-scroll'  style={{padding: "0px 0px", marginTop: "10px"}}  /></a>
              

            </Box>
            {/* <img src={"proteinarium_logo.jpeg"} /> */}
          {/* </Tooltip> */}
          <Box className={classes.inline} fontWeight={300} fontSize={50} fontFamily={'Oswald'}>
              | 
              {/* <Dropdown value={species} onChange={e => setSpecies(e.target.value)} data={SPECIES_DATA}/> */}
            </Box>

          <Box className={classes.inline} fontWeight={300} fontSize={40} fontFamily={'Oswald'} letterSpacing={2.5}>
            &nbsp; {projectName}
            {/* <Dropdown value={species} onChange={e => setSpecies(e.target.value)} data={SPECIES_DATA}/> */}
          </Box>

          {/*<Tooltip title={<Box fontWeight={300} fontSize={14} style={{ color: "white" }}>This is your user ID specified. Click to copy to clipboard.</Box>}>  */}
          {/*/!*</Box>"This is your user ID specified. Click to copy to clipboard.*!/*/}
          {/*  */}
          {/*</Tooltip>*/}
          <Tooltip title="Tonic of the key">
            <Box className={classes.inline}>
              {/* <Dropdown value={keySignature} onChange={e => setKeySignature(e.target.value)} data={KEY_SIGNATURE_DATA}/> */}
            </Box>
          </Tooltip>
          <Tooltip title="Mode of the key">
            <Box className={classes.inline}>
              {/* <Dropdown value={mode} onChange={e => setMode(e.target.value)} data={MODE_DATA}/> */}
            </Box>
          </Tooltip>
        </Box>
        <Box className={classes.right}>
        <Tooltip title={<Box fontWeight={300} fontSize={14} style={{ color: "white" }}>General tips on how to navigate the site and analysis program.</Box>}>
            <Button className={useStyles().button} onClick={() => setShowHelp(true)}>
            <Box className={classes.inline} fontWeight={400} fontSize={20} fontFamily={'Oswald'} letterSpacing={0.5}>
                Help
            </Box>
            </Button>
          </Tooltip>

          
          <Tooltip open={open} onClose={handleClose} onOpen={handleOpen} title={<Box fontWeight={300} fontSize={14} style={{ color: "white" }}>{copyProjectToolTip}</Box>}>
            <Button className={useStyles().button} onClick={() => createProjectCopy(address, id).then(() => {
              setCopyProjectToolTip("New Link Saved to Clipboard!")
              handleOpen();
            })} onMouseOut={() => {
              setCopyProjectToolTip("Copy a link to a copy of the current project.")
            }}>
              <Box className={classes.inline} fontWeight={400} fontSize={20} fontFamily={'Oswald'} letterSpacing={0.5}>
                Share Link
              </Box>
            </Button>
          </Tooltip>


          <Tooltip title={<Box fontWeight={300} fontSize={14} style={{ color: "white" }}>Create a new analysis session.</Box>}>
            <Button className={useStyles().button}
              onClick={(e) => {

                let newContent = "Issue Creating new Project";
                console.log(window.location.pathname)

                if (window.location.pathname.includes("/project")) {
                  newContent = <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} gridGap={"10px"} margin={"auto"} width={"100%"} height={"100%"}>
                      <Box display={"flex"} flexDirection={"row"} justifyContent={"center"} gridGap={"30px"}>
                        <button onClick={() => {
                          navigate('/newProject', {replace: false, state: {"UsingOldGenesets": true, "id":id, "data": configData}})
                        }}>Use Previous Genesets</button>
                        <button onClick={() => {navigate('/newProject', {replace: false, state: {"UsingOldGenesets": false}})}}>Use New Genesets</button>
                      </Box>
                      <div>
                        <p>Geneset 1: <b>{configData["geneset1"]}</b></p>
                        <p>Geneset 2: <b>{configData["geneset2"]}</b></p>
                      </div>
                    </Box>
                  modalOpen(newContent)
                } else if (window.location.pathname.includes("/newProject")) {
                  //Navigate to new Project
                  navigate('/newProject', {replace: false, state: {"UsingOldGenesets": false}})
                }
                // Navigate to new Project
                // navigate('/newProject', {replace: false, state: {"UsingOldGenesets": false}})

                //e.preventDefault();
                //window.location.href='../../newProject';
              }}
            >
            <Box className={classes.inline} fontWeight={400} fontSize={20} fontFamily={'Oswald'} letterSpacing={0.5}>
                New Session
            </Box>
            </Button>
          </Tooltip>

          {/* <Load load={load}/> */}
          <Tooltip title={<Box fontWeight={300} fontSize={14} style={{ color: "white" }}>Save your current session's config, ID, and files.</Box>}>
            <Button className={useStyles().button} 
            onClick={() => { 
              filePath(address, id, "projectzip").then((foundLink) => {
                //TODO: edge case handling? should never occur tho

                try {saveAs(foundLink,foundLink.substring(foundLink.lastIndexOf("\\")+1)) }
                catch { alert("PROJECT NOT DONE LOADING YET") }

                const downloadTxtFile = () => {

                  let saveText = "";
                  //"Link To Website: " + address + id + "\n"
                  //saveText = saveText + "Data Expires on : " + "Insert Date" + "\n"
                  if (configData !== undefined) {
                    let keys = Object.keys(configData)
                    console.log(configData)
                    for (let i in keys) {
                      /*
                      if (keys[i] === "geneset1" || keys[i] === "geneset2") {
                        saveText = saveText + keys[i] + ": " + configData[keys[i]]["0"]["name"] + "\n"
                      } else {
                        saveText = saveText + keys[i] + ": " + configData[keys[i]] + "\n"
                      }
                      */
                      saveText = saveText + keys[i] + ": " + configData[keys[i]] + "\n"
                    }
                  } else {
                    //TODO cover error here
                    return
                  }

                  const element = document.createElement("a");
                  const file = new Blob([saveText], {
                    type: "text/plain"
                  });
                  element.href = URL.createObjectURL(file);
                  element.download = projectName + "_Config.txt";
                  document.body.appendChild(element);
                  element.click();
                };

                console.log("got here")
                downloadTxtFile()

              })

            }}>
            <Box className={classes.inline} fontWeight={400} fontSize={20} fontFamily={'Oswald'} letterSpacing={0.5}>
                Save Session
            </Box>
            </Button>
          </Tooltip>
          {/* <Tooltip title="More info about the Proteinarium project and its usage">
            <Button>
              Info
            </Button>
          </Tooltip> */}
          
          {/* <Tooltip title="Saves all files generated so far to a .zip file">
            <Button>
              Save to .ZIP
            </Button>
          </Tooltip> */}
          {/* <Clear clearMelody={clearMelody} clearCounterMelody={clearCounterMelody}/> */}
          {/* <Tooltip title="Play audio">
            <IconButton onClick={play} size="small">
              <Icon>{isPlaying ? "stop" : "play_arrow"}</Icon>
            </IconButton>
          </Tooltip> */}
          {/* <Tooltip title={<Box fontWeight={300} fontSize={14} style={{ color: "white" }}>Save to .ZIP file</Box>}>
            <IconButton size="small" onClick={() => { 
              filePath(address, id, "projectzip").then((foundLink) => {
                //TODO: edge case handling? should never occur tho

                try {saveAs(foundLink,foundLink.substring(foundLink.lastIndexOf("\\")+1)) }
                catch { alert("PROJECT NOT DONE LOADING YET") }
              })
             }}>
              <Icon>download</Icon>
            </IconButton>
          </Tooltip>  */}
        </Box>
      </Toolbar>
    </AppBar>
  </ThemeProvider>);
  }

export default Navbar;