import { useState } from 'react'
import emailjs from 'emailjs-com'
import {FaGithub, FaNewspaper} from 'react-icons/fa'



const initialState = {
  name: '',
  email: '',
  message: '',
}
export const Contact = (props) => {

  const getYear = () =>  { return (new Date().getFullYear()) }

  const [{ name, email, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)
    emailjs
      .sendForm(
        'proteinarium', 'prot_template', e.target, "JpWGtrV8QFtdiW3xY"
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
        },
        (error) => {
          console.log(error.text)
        }
      )
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Get In Touch</h2>
                  
                
                <p>
                This project was brought together by co-PIs:
                </p>
                
                <h3 style={{marginTop: "40px"}}>
                  <b>alper_uzun at brown dot edu</b> <br></br> <br></br>
                  <b>Jessica_Schuster at brown dot edu</b>
                </h3>
                <p>
                Please feel free to email for any inquiries on usage of the webapp.
                </p>
              </div>
              
              
              {/* <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Name'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Message'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Send Message
                </button>
              </form> */}
            </div>
            
          </div>

          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={'https://github.com/alperuzun/Proteinariumweb'} target={'blank'}>
                      <i className='fa fa-github'>
                        <FaGithub></FaGithub>
                      </i>
                      
                      {/* <FaGithub/> */}
                      {/* hi */}
                    </a>
                  </li>
                  <li>
                    <a href={'https://www.sciencedirect.com/science/article/pii/S0888754320303050'} target={'blank'}>
                      <i className='fa fa-github'>
                        <FaNewspaper></FaNewspaper>
                      </i>
                      
                      {/* <FaGithub/> */}
                      {/* hi */}
                    </a>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy;{getYear()} Dr. Alper Uzun's{' '}
            <a href='https://sites.brown.edu/gmilab/people/' rel='nofollow' target='_blank'>
            Genomics and Machine Intelligence Lab
            </a>,{' '}
            <a href='https://www.brown.edu/' rel='nofollow' target='_blank'>
              Brown University
            </a>{' '}
            
          </p>
        </div>
      </div>
    </div>
    
  )
}
