import {Box, Container, Button, makeStyles, Typography, createTheme, ThemeProvider, Icon} from "@material-ui/core";
// import { createTheme } from "@mui/material/styles";
import React from "react";
import ClusterRender, {getClusterLinksMap} from "./ClusterRender";
import {useEffect, useRef, useState} from 'react'
import {Resizable} from "re-resizable";
import filePath from "../backendconnect/FilePath"

import { saveAs } from "file-saver";
import {Link} from "react-router-dom";
// import {useRef} from "react";


const useStyles = makeStyles(height => ({
  box: boxHeight => ({
    border: "2px solid black",
    marginLeft: 2,
    // marginBottom: 200,
    width: "100%",
    height: "70%",
    borderRadius: 8
  }),
  title: {
    verticalAlign: "top"
  },
  cap: {
    backgroundColor: "#C4C4C4",
    marginLeft: "-2%",
    marginRight: "-2%",
    border: "1px solid black",
    borderRadius: 4
  }
}))

const theme = createTheme({
  palette: {
    primary: {
      main: "#EEF0F2"
    },
    secondary: {
      main: "#EEF0F2",
    },
  },
  typography: {
    fontFamily: [
      'Oswald',
      'Oswald Light',
      'Open Sans'
    ].join(','),
  },
})
//TODO: legitimate fix for cap, not the silly margin stuff



function ClusterBox({ssID, cID, id, address, clusterExpand, boxTitle, boxHeight, content,curCID, buttonList, screenshotModalView, setOpen, showClusterInfo, onClickFxn, analysisType}) {
  const classes = useStyles(boxHeight);
  const upperTitle = boxTitle.toUpperCase();

  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const listRef = useRef(null);

  const [clusterToRender, setClusterToRender] = useState(<div>Waiting for Cluster To be Picked</div>)

  useEffect(() => {
    setWidth(listRef.current.offsetWidth);
    setHeight(listRef.current.offsetHeight);
    console.log("Clusterbox content is:")
    console.log(content)
    //EDIT: Added if here.
    console.log(ssID)
    if(analysisType === "newProjectSample") {
      onClickFxn(ssID)
    }
  }, []);

  let marginTopVal = "0"
  if (buttonList.current.length === 0) {
    marginTopVal = "30px"
  } else {
    marginTopVal = "0"
  }

  const onResize = (event, {element, size, handle}) => {
    console.log(size)
    setWidth(listRef.current.offsetWidth);
    setHeight(listRef.current.offsetHeight);
  };

  useEffect(() => {
    //
  });

  console.log("GONNA MOUNT SOON")
  console.log(content);

  return (
      <div ref={listRef} style={{"marginTop": marginTopVal}}>
        <ThemeProvider theme={theme}>
          <Container id="box" className={classes.box}>
            <Resizable defaultSize={{
              width:width,
              height:"500px",
            }} onResize={onResize}>
              <Box id="cap" className={classes.cap}>
                <Box fontWeight={300} fontSize={20} fontFamily={'Oswald'} letterSpacing={2.5} id="title" className={classes.title} textAlign={"center"}>
                  <button onClick={() => clusterExpand()} style={{"textAlign": "left"}}><Icon>{"expand"}</Icon></button> <b>{curCID} {upperTitle}</b>
                  {/*<Link to={"/ClusterSave"} state={{data: <div>Test</div>}}>*/}
                    <button onClick={() => {
                      screenshotModalView.current = true;
                      //showClusterInfo(cID);
                      if (screenshotModalView.current) {
                        setOpen();
                      }
                    
                    }
                      //     filePath(address, id, cID + "_" + "Group1AndGroup2").then((foundLink) => {
                      //   //TODO: edge case handling? should never occur tho
                      //   try {saveAs(foundLink,foundLink.substring(foundLink.lastIndexOf("\\")+1)) }
                      //   catch { alert("CLUSTER NOT LOADED YET") }
                      // })
                    }
                            style={{"textAlign": "left"}}><Icon>{"save"}</Icon></button>
                  {/*</Link>*/}
                </Box>
              </Box>
                <div>
                  {content}
                </div>
            </Resizable>
          </Container>
        </ThemeProvider>
      </div>
);
}

export default ClusterBox;
