//From: ConfigLayout.js
import {makeStyles, createTheme, ThemeProvider, Box, Button} from "@material-ui/core";

import React, {useState, useEffect} from "react";
import Help from "../MiscComponents/help/Help"
import FrontendLayout from "./Layout"
import NewProject from "../SetupComponents/NewProject"
import {Link, useLocation} from 'react-router-dom';

import {Navigation} from "../MiscComponents/navbar/ConfigNav"
import GeneralConfig from "../SetupComponents/GeneralConfig";
import {AwesomeButton} from "react-awesome-button";


function ConfigLayout(props) {

    const [showHelp, setShowHelp] = useState(false);
    const [projectInit,setProjectInit] = useState(true);

    const location = useLocation();
    console.log(location.state)

    let useOldGenesets = false
    let previousID = ""
    let previousConfig = {}
    if (location.state !== undefined && location.state !== null && location.state.UsingOldGenesets) {
        useOldGenesets = true
        previousID = location.state.id
        previousConfig = location.state.data
    }

    const useStyles = makeStyles({
        clusterbutton: {
            margin: 100,
        }
    });

    const theme = createTheme({
        palette: {
            primary: {
                main: "#EEF0F2"
            },
            secondary: {
                main: "#EEF0F2",
            },
        },
        typography: {
            fontFamily: [
                'Oswald',
                'Open Sans'
            ].join(','),
        },
    })


    return (
        <div style={{textAlign: "center"}}>
            <ThemeProvider theme={theme}>
                <Help show={showHelp} setShow={setShowHelp} theme={theme}/>
                <Navigation/>
                {projectInit
                    ?
                    <div className="ConfigSetup">
                        <div className={"ConfigBox"}>
                            <div>
                                <Box fontWeight={700} fontSize={30} fontFamily={'Oswald'} letterSpacing={2}>
                                    <b>NEW PROJECT CONFIGURATION</b>
                                </Box>
                                <Box fontWeight={700} fontSize={20} fontFamily={'Oswald'} letterSpacing={2}
                                     style={{display: 'flex', justifyContent: 'space-around', margin: '20px'}}>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <img src="sample_figure.png" className="img-responsive" alt="" style={{maxWidth: "30%", margin: '20px'}}/>
                                        <a href="/NewProjectSample" style={{ textDecoration: 'none', color: 'white' }}>
                                            <Button color={'inherit'} fontFamily={'Open Sans'} style={{fontSize: "1em", border: "1px solid white"}}>
                                                SINGULAR SAMPLE ANALYSIS
                                            </Button>
                                        </a>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <img src="singular_figure.png" className="img-responsive" alt="" style={{maxWidth: "30%", margin: '20px'}}/>
                                        <a href="/NewProjectSingular" style={{ textDecoration: 'none', color: 'white' }}>
                                            <Button color={'inherit'} fontFamily={'Open Sans'} style={{fontSize: "1em", border: "1px solid white"}}>
                                                SINGULAR GENESET ANALYSIS
                                            </Button>
                                        </a>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <img src="dichotomous_figure.png" className="img-responsive" alt="" style={{maxWidth: "30%", margin: '20px'}}/>
                                        <a href="/NewProjectDichotomous" style={{ textDecoration: 'none', color: 'white'}}>
                                            <Button color={'inherit'} fontFamily={'Open Sans'} style={{fontSize: "1em", border: "1px solid white"}}>
                                                DICHOTOMOUS GENESET ANALYSIS
                                            </Button>
                                        </a>
                                    </div>
                                </Box>
                            </div>
                        </div>

                        <br></br>
                        <br></br>

                        <div className={"OptionButtons"}>
                            <Link to="/"><AwesomeButton className={"aButtonLeft"}>Cancel</AwesomeButton></Link>
                            <br></br>

                        </div>
                    </div>
                    : <FrontendLayout/>}
            </ThemeProvider>


        </div>
    );
}


export default ConfigLayout;
