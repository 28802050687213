import { useState, useEffect, useRef } from "react";
import { Navigation } from "./landingcomponents/navigation";
import { Header } from "./landingcomponents/header";
import { Features } from "./landingcomponents/features";
import { About } from "./landingcomponents/about";

import { Usage } from "./landingcomponents/usage";

import { Contact } from "./landingcomponents/contact";
import SmoothScroll from "smooth-scroll";
import "./App.css";


import ConfigSetup from "./SetupComponents/archive/ConfigSetup";
import NewProject from "./SetupComponents/NewProject";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

import DendrogramLoad from "./DendrogramPane/DendrogramLoad";
import Dendrogram from "./DendrogramPane/Dendrogram";
import ConfigLayout from "./Layouts/ConfigLayout"
import ClusterRender from "./ClusterPane/ClusterRender";



import D3TreeTest from "./DendrogramPane/archive/D3TreeTest";

import ClusterSave from "./ClusterPane/ClusterSave";
import PreConfigLayout from "./Layouts/PreConfigLayout";
import ConfigLayoutDichotomous from "./Layouts/ConfigLayoutDichotomous";
import ConfigLayoutSingular from "./Layouts/ConfigLayoutSingular";
import ConfigLayoutSample from "./Layouts/ConfigLayoutSample";


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

function App() {

  const [id, setID] = useState("");
  const idString = useRef("");
  const [projectName, setProjectName] = useState("")
  const [configData, setConfigData] = useState();
  const [analysisType, setAnalysisType] = useState("");



    console.log(process.env.REACT_APP_PRODUCTION === 'true')

  let prod = process.env.REACT_APP_PRODUCTION === 'true'

  let endpointAddress = "http://localhost:4567/"
  if (prod) {
      endpointAddress = "https://proteinarium.brown.edu:4567/"
  }

return (
  <div className="App">

    <Routes>
      <Route path="/" element={<LandingPage/>}/>
      <Route path="/SetConfig" element={<ConfigSetup address={endpointAddress} setID={setID} idString={idString}/>}/>
      <Route path="/OldNewProject" element={<NewProject address={endpointAddress} setID={setID} idString={idString}/>}/>
      <Route path="/DendrogramLoad" element={<DendrogramLoad address={endpointAddress} id={id} idString={idString}/>}/>
      <Route path="/test" element={<D3TreeTest address={endpointAddress} id={id} idString={idString}/>}/>
      <Route path="/test2" element={<Dendrogram address={endpointAddress} id={id} idString={idString}/>}/>
      <Route path="/NewProjectConfig" element={<PreConfigLayout/>}/>
      {/*<Route path="/NewProject" element={<ConfigLayout address={endpointAddress} id={id} setID={setID} idString={idString} setProjectName={setProjectName} setConfigData={setConfigData}/>}/>*/}

        {/*  EDIT: Adding analysisType prop to following three: */}
        <Route path="/NewProjectDichotomous" element={<ConfigLayoutDichotomous  address={endpointAddress} id={id} setID={setID} idString={idString} setProjectName={setProjectName} setConfigData={setConfigData} setAnalysisType={setAnalysisType}/>}/>
        <Route path="/NewProjectSingular" element={<ConfigLayoutSingular address={endpointAddress} id={id} setID={setID} idString={idString} setProjectName={setProjectName} setConfigData={setConfigData} setAnalysisType={setAnalysisType}/>}/>
        <Route path="/NewProjectSample" element={<ConfigLayoutSample address={endpointAddress} id={id} setID={setID} idString={idString} setProjectName={setProjectName} setConfigData={setConfigData} setAnalysisType={setAnalysisType}/>}/>

        <Route path="/cluster-demo" element={<ClusterRender address={endpointAddress} id={id} idString={idString}/>}/>

        {/*  EDIT: Adding analysisType prop for access in DendrogramLoad */}
      <Route path={"/project/:id"} element={<DendrogramLoad analysisType={analysisType} address={endpointAddress} projectName={projectName} configData={configData}/>}/>

        <Route path={"/clusterSave"} element={<ClusterSave/>}/>
      <Route path={"/admin"} element={<ClusterSave/>}/>
    </Routes>
    
  </div>
);
}

export default App;

function LandingPage() {


  return (
    <div>
      <Navigation />
      <Header />
      <Features  />
      {/*<Usage />*/}
      <About/>
      <Contact />
      
    </div>
  );
};
