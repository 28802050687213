import { Box, Container, makeStyles, Typography, createTheme, ThemeProvider } from "@material-ui/core";
// import { createTheme } from "@mui/material/styles";
import {useState, useEffect, useRef, React} from "react";
import CommandButton from "./CommandButton";
// import {useRef} from "react";


const useStyles = makeStyles(height => ({
  box: boxHeight => ({
    border: "2px solid black",
    paddingBottom: 100,
    marginTop: 30,
    marginLeft: 2,
    width: "initial",
    height: "100%", 
    borderRadius: 8
  }),
  title: {
    verticalAlign: "top"
  },
  cap: {
    backgroundColor: "#C4C4C4",
    marginLeft: "-2%",
    marginRight: "-2%",
    border: "1px solid black",
    borderRadius: 4
  }
}))

const theme = createTheme({
  palette: {
    primary: {
      main: "#EEF0F2"
    },
    secondary: {
      main: "#EEF0F2",
    },
  },
  typography: {
    fontFamily: [
      'Oswald',
      'Oswald Light',
      'Open Sans'
    ].join(','),
  },
})

//TODO: legitimate fix for cap, not the silly margin stuff



function InfoBox({boxTitle, boxHeight,content,curCID,backendFxn, descSet, address, id}) {

  console.log(curCID)
  const [onload, setOnload] = useState(false);
  // EDIT:
  const [respLoad, setRespLoad] = useState(true);
  const [clickedButton, setClickedButton] = useState("")

  useEffect(() => {
    setClickedButton("")
  }, [curCID])

  var commandList = []
  if (curCID.includes("<=>")) {
    let curCID2 = curCID.replace("<=>"," ")
    commandList = [
      <CommandButton clickedButton={clickedButton} setClickedButton={setClickedButton} respLoad={respLoad} setRespLoad={setRespLoad} cmd={"info"} loadState={onload} title={"general info"} curInfo={content} backendCMD={backendFxn} setInfo={descSet} cID={curCID} isTable={false} id={id} address={address}></CommandButton>,
      // <CommandButton cmd={"distance"} title={"Distance"} curInfo={content} backendCMD={backendFxn} setInfo={descSet} cID={curCID}></CommandButton>,
      // <CommandButton cmd={"proteins"} title={"Proteins Implicated"} curInfo={content} backendCMD={backendFxn} setInfo={descSet} cID={curCID} isTable={true}></CommandButton>,
      <CommandButton clickedButton={clickedButton} setClickedButton={setClickedButton} respLoad={respLoad} setRespLoad={setRespLoad} cmd={"genes"} loadState={onload} title={"Genes Implicated"} curInfo={content} backendCMD={backendFxn} setInfo={descSet} cID={curCID} isTable={true} id={id} address={address}></CommandButton>,
      <CommandButton clickedButton={clickedButton} setClickedButton={setClickedButton} respLoad={respLoad} setRespLoad={setRespLoad} cmd={"weights"} loadState={onload} title={"Interaction Scores"} curInfo={content} backendCMD={backendFxn} setInfo={descSet} cID={curCID} isTable={true} id={id} address={address}></CommandButton>,
      <CommandButton clickedButton={clickedButton} setClickedButton={setClickedButton} respLoad={respLoad} setRespLoad={setRespLoad} cmd={"topcentrality"} loadState={onload} title={"Network Parameters"} curInfo={content} backendCMD={backendFxn} setInfo={descSet} cID={curCID} isTable={true} id={id} address={address}></CommandButton>,
      // <CommandButton cmd={"distance"} title={"Distance"} curInfo={content} backendCMD={backendFxn} setInfo={descSet} cID={curCID2} isTable={false}></CommandButton>,
      <CommandButton clickedButton={clickedButton} setClickedButton={setClickedButton} respLoad={respLoad} setRespLoad={setRespLoad} cmd={"sep"} loadState={onload} title={"Separation"} curInfo={content} backendCMD={backendFxn} setInfo={descSet} cID={curCID2} isTable={false} id={id} address={address}></CommandButton>
    ]
  } else {
    commandList = [
      <CommandButton clickedButton={clickedButton} setClickedButton={setClickedButton} respLoad={respLoad} setRespLoad={setRespLoad} cmd={"info"} title={"general info"} curInfo={content} backendCMD={backendFxn} setInfo={descSet} cID={curCID} isTable={false} id={id} address={address}></CommandButton>,
      // <CommandButton cmd={"distance"} title={"Distance"} curInfo={content} backendCMD={backendFxn} setInfo={descSet} cID={curCID}></CommandButton>,
      // <CommandButton cmd={"proteins"} title={"Proteins Implicated"} curInfo={content} backendCMD={backendFxn} setInfo={descSet} cID={curCID} isTable={true}></CommandButton>,
      <CommandButton clickedButton={clickedButton} setClickedButton={setClickedButton} respLoad={respLoad} setRespLoad={setRespLoad} cmd={"genes"} title={"Genes Implicated"} curInfo={content} backendCMD={backendFxn} setInfo={descSet} cID={curCID} isTable={true} id={id} address={address}></CommandButton>,
      <CommandButton clickedButton={clickedButton} setClickedButton={setClickedButton} respLoad={respLoad} setRespLoad={setRespLoad} cmd={"weights"} title={"Interaction Scores"} curInfo={content} backendCMD={backendFxn} setInfo={descSet} cID={curCID} isTable={true} id={id} address={address}></CommandButton>,
      <CommandButton clickedButton={clickedButton} setClickedButton={setClickedButton} respLoad={respLoad} setRespLoad={setRespLoad} cmd={"topcentrality"} title={"Network Parameters"} curInfo={content} backendCMD={backendFxn} setInfo={descSet} cID={curCID} isTable={true} id={id} address={address}></CommandButton>,
    ]
  }
  
  // if (curCID !== "") {
  //   setCommandList()
  // }

  //TODO: there is most definitely a better way to do this:
  // ... resetting the commands available by letting a command button
  // remove all the buttons including itself?? nasty
  const flushCommandList = function() {
    commandList = []
  }


  // seperation testing (one test)
  // Info for each cluster
  // List of unqiue genes for C#1 and C#2, and overlapping
  // most connected genes (hap or hot genes?)
  // Large table to do multiple comparison (i.e. does a unqiue node have high degree on one cluster but not the other)
  // Split cluster pane in two and color the unique genes purple (i.e. only shows up on one and not both)


  const classes = useStyles(boxHeight);
  const upperTitle = boxTitle.toUpperCase();
  return (
    <ThemeProvider theme={theme}>
      <Container id="box" className={classes.box} >
        <Box id="cap" className={classes.cap}>
          
          <Box fontWeight={300} fontSize={20} fontFamily={'Oswald'} letterSpacing={2.5} id="title" className={classes.title}>
            <b>{curCID} {upperTitle}</b>
          </Box>
          {content.length > 0 ? <Box display={"flex"} justifyContent={"flex-start"}>

          </Box> : <div></div>}
          <Box display={"flex"} justifyContent={"flex-start"}>
          {commandList.map(e =>
              <Box>{ e }</Box>)}
          </Box>
        </Box>

        {/*{respLoad === false && <Box>Please wait, response loading...</Box>}*/}

        {/*<div style={{whiteSpace: "pre-wrap"}}>{content}</div>*/}
        <div style={{ position: 'relative' }}>
          {respLoad === false && (
              <div
                  style={{
                    position: 'absolute',
                    top: 20,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(255, 255, 255, 255)', // Adjust the opacity and color as needed
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#000000',
                    fontSize: '18px',
                    fontWeight: 'bold'
                  }}
              >
                Please wait, response loading...
              </div>
          )}

          { respLoad === true &&
            <div
                style={{
                  whiteSpace: 'pre-wrap',
                  opacity: respLoad === false ? 0.5 : 1, // Adjust the opacity value as needed
                }}
            >
              {content}
            </div>
          }
        </div>

      </Container>
    </ThemeProvider>
  );
}

export default InfoBox;
