import { AppBar, Box, Dialog, makeStyles, Tab, Tabs, Icon, IconButton} from "@material-ui/core";
import React from "react";
import About from "./About";
// import HotKeys from "./HotKeys";

const useStyles = makeStyles({
  tab: {
    position: "relative",
    height: "80vh",
    overflowY: "auto",
  },
  paper: {
    overflowY: 'unset',
  },
  customizedButton: {
    width: 40,
    height: 40,
    position: 'absolute',
    left: '96%',
    top: '1%',
    backgroundColor: 'lightgray',
    color: 'gray',
  }
})

const TabPanel = ({children, value, index, ...other}) => {
  return <Box
    role="tabpanel"
    hidden={value !== index}
    p={3}
    {...other}
  >
    {value === index && children}
  </Box>;
}

const Help = ({show, setShow,theme}) => {
  const classes = useStyles();
  const [tab, setTab] = React.useState(0);
  return <Dialog maxWidth="lg" fullWidth open={show} onClose={() => setShow(false)}>
    <AppBar position="static">
      <Tabs value={tab} onChange={(e, v) => setTab(v)}>
        <Tab label="About" style={{fontSize: "2rem"}}/>
        <Tab label="Citation" style={{fontSize: "2rem"}}/>
        {/* <Tab label="Hotkeys"/> */}
      </Tabs>
      <IconButton className={classes.customizedButton} onClick={() => {setShow(false)}}>
        <Icon>{"close"}</Icon>
      </IconButton>
    </AppBar>
    <TabPanel className={classes.tab} value={tab} index={0}>
      <About themeUsed={theme} setShow={setShow}/>
    </TabPanel>
    {/* <TabPanel className={classes.tab} value={tab} index={1}> */}
      {/* <HotKeys/> */}
    {/* </TabPanel> */}
  </Dialog>
}

export default Help;