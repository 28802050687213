//FROM: sendZipConfig.js
import axios from "axios";

export async function sendZipCommandSample(props, data, extraInfo) {

    let zip = require('jszip')();

    let formData1 = new FormData();

    // let imagefile1 = document.querySelector('#group1GeneSetFile')
    // let imagefile2 = document.querySelector('#group2GeneSetFile')

    let imagefile1 = data.geneset1[0]
    // let imagefile2 = data.geneset2[0] //

    // let includedGeneset2 = data.geneset2["length"] === 0

    console.log(data)

    let projectName = data.title

    let maxPathLength = !isNaN(data.pathLength) ? data.pathLength : 3
    let maxVertices = !isNaN(data["vertices"]) ? data["vertices"]: 50
    let repulsionConstant = !isNaN(data["repulsion"]) ? data["repulsion"] : 1.2
    let clusterThreshold = !isNaN(data["threshold"]) ? data["threshold"] : 0.33
    let minpath = !isNaN(data["minpath"]) ? data["minpath"] : 0
    let maxpath = !isNaN(data["maxpath"]) ? data["maxpath"] : 200

    let group1alias = data.group1alias;
    let group2alias = data.group2alias;

    let algorithm = data.algorithm
    let database = data["db"]

    console.log(data)
    console.log(database)
    console.log(isNaN(data["db"]))

    let genefiles = zip.folder("");
    if (!extraInfo["usingOldGeneset"]) {
        genefiles.file("group1GeneSetFile.txt", imagefile1)
        // genefiles.file("group2GeneSetFile.txt", imagefile2)
    }

    console.log("GETTTING A NEW CONFIG PLEASE DONT RUN TWICE")

    //TODO: FIX BUG: BOOTSTRAPPINGROUNDS = NAN50??
    let configText =
        "maxPathLength=" + maxPathLength + " " +
        "maxVerticesToRender=" + maxVertices + " "
        + "repulsionConstant=" + repulsionConstant + " "
        + "metaClusterThreshold=" + clusterThreshold + " "
        + "minInteractomeConfidence=" + minpath + " "
        + "maxPathCost=" + maxpath + " "
        + "projectName=" + projectName + " " + "databaseType=" + database + " "
        + "bootstrappingRounds=0" + " "
        + "group1alias=" + group1alias + " "
        + "group2alias=" + group2alias;

    console.log(configText)

    let configJSON = {
        headers: {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
        }
    }

    let configFILE = {
        headers: {
            "Content-Type": "multipart/form-data",
            'Access-Control-Allow-Origin': '*',
        }
    }

    let done = false
    let IDfound = ""

    // TODO this generateAsync could be an issue
    let blob = await zip.generateAsync({type:"blob"})
    formData1.append("genesets", blob);
    await getID()

    /*
    .then(function (blob) {

        formData1.append("genesets", blob);
        getID()

    });
     */

    async function getID() {

        let ID = ""

        //EDIT: added bool
        const toSend = {
            ssBool : true
        };

        let res = await axios.post(
            props.address + "id",
            toSend,
            configJSON
        )

        let idVal = res.data["ID"]
        props.idString.current = ID
        await sendData(idVal)
    }

    async function sendData(ID) {
        //Install and import this!
        //TODO: Fill in 1) location for request 2) your data 3) configuration
        if (!extraInfo["usingOldGeneset"]) {
            //console.log(imagefile1.value)
            //console.log("|" + imagefile2.value + "|")
            console.log(props.address)
        }


        let saveText = "Link To Website: " + document.location.host + "/project/" + ID + "\n"
        saveText = saveText + "Data Expires On: " + "Insert Date" + "\n"
        if (data !== undefined) {
            let keys = Object.keys(data)
            console.log(extraInfo)
            console.log(data)
            for (let i in keys) {

                // if(!includedGeneset2 && keys[i] === "geneset2") { //
                //     continue;
                // }

                if ((keys[i] === "geneset1" || keys[i] === "geneset2") && extraInfo["usingOldGeneset"]) {
                    saveText = saveText + keys[i] + ": " + extraInfo["previousConfig"][keys[i]] + "\n"
                } else if (keys[i] === "geneset1" || keys[i] === "geneset2") {
                    //saveText = saveText + keys[i] + ": " + data["previousConfig"]["geneset2"] + "\n"
                    saveText = saveText + keys[i] + ": " + data[keys[i]]["0"]["name"] + "\n"
                } else {
                    saveText = saveText + keys[i] + ": " + data[keys[i]] + "\n"
                }
            }
        }

        console.log(data)

        const toSend = {
            config : configText,
            dataRaw : saveText,
            needGeneset2: "n",
            usingOldGeneset: extraInfo["usingOldGeneset"],
            previousID: extraInfo["previousID"]
        };

        if (!extraInfo["usingOldGeneset"]) {
            if (imagefile1.value !== "") {
                let res1 = await axios.post(
                    props.address + "zipfilesample/" + ID,
                    formData1,
                    configFILE
                )
            }
        }

        let res2 = await axios.post(
            props.address + "configsample/" + ID,
            toSend,
            configJSON
        )

        IDfound = ID
    }

    console.log(IDfound)
    return IDfound
}
