import axios from "axios";
import { stackOffsetSilhouette } from "d3";
import ProgressBar from "../MiscComponents/primitive/Progress";


export default async function IDLoad(address, ID) {

  const toSend = {
  };

  let configJSON = {
      headers: {
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin': '*',
      }
  }

  let response = await axios.post(
      address + "idLoad/" + ID,
      toSend,
      configJSON
  )

  return response.data
}