import {Box, Container, Button, makeStyles, Typography, createTheme, ThemeProvider, Icon} from "@material-ui/core";
// import { createTheme } from "@mui/material/styles";
import React from "react";
import { useLocation } from "react-router-dom";

function ClusterSave(props) {

    const location = useLocation();
    console.log(props, " props");
    console.log(location, " useLocation Hook");
    const state = location.state;
    //console.log(data)

    let value = <div></div>
    if (state !== null) {
        value = state.data
    }

  return (
      <div>
          <p>Value</p>
          {value}
      </div>
  );
}

export default ClusterSave;
