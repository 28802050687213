//NOTE: WILL BE ARCHIVED. * NERVOUS LAUGHTER * Moved to GeneralConfigSingular and GeneralConfigDichotomous.


import {useForm} from "react-hook-form";
import React, {useState, useRef, useEffect, useCallback} from "react";
import {useNavigate} from 'react-router-dom';
import {sendZipCommand} from "./sendZipConfig.js"
import './GeneralConfig.css';

import {AwesomeButton, AwesomeButtonProgress} from "react-awesome-button";

import {
    AppBar,
    Box,
    Button,
    Typography,
    Icon,
    IconButton,
    makeStyles,
    createTheme,
    ThemeProvider,
    Toolbar,
    Tooltip,
    FormHelperText
} from "@material-ui/core";

function GeneralConfig(props) {

    const navigate = useNavigate();

    const {setValue, register, handleSubmit, watch, formState: {errors}} = useForm();
    const watchDB = watch("db");
    const oldDB = useRef(watchDB)

    const onSubmit = (data) => {
        console.log("GOT HERE AGAIN")
        console.log(data)
        let extraInfo = {}
        extraInfo["usingOldGeneset"] = props.useOldGenesets;
        extraInfo["previousID"] = props.previousID;
        extraInfo["previousConfig"] = props.previousConfig;

        sendZipCommand(props, data, extraInfo).then((ID) => {
            props.setID(ID);
            props.setConfigData(data)
            //setClickedNewProject(true);
            navigate('/project/' + ID, {replace: true})
            console.log(props)
            props.setProjectName(data["title"])
        })
    };

    useEffect(() => {
        console.log(watchDB)
        console.log(oldDB.current)

        changeMaxPathCost(watchDB)
        oldDB.current = watchDB
    }, [watchDB]);


//   const handleOnClick = useCallback(() => {
//     console.log("GOT HERE AGAIN")
//     sendZipCommand(props).then((ID) => {
//         props.setID(ID);
//         //setClickedNewProject(true);
//         navigate('/project/' + ID, {replace: true})
//     })
// }, [navigate]);

    const useStyles = makeStyles({
        submitButton: {
            alignItems: 'center',
            flexDirection: 'row',
            width: '20vw',
            padding: 5,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: 'lightblue',
            borderRadius: 5,
            color: 'black',
        }
    });

    const classes = useStyles();


    const [show, setShow] = useState(false);
    const [fileNumber, setFileNumber] = useState(1);

    function changeMaxPathCost(currDB) {
        console.log(currDB)
        if (currDB === "string") {
            setValue("maxpath", 1000)
        } else if (currDB === "intact") {
            console.log("setting to intact")
            setValue("maxpath", 10000)
        } else if (currDB === "mint") {
            console.log("setting to mint")
            setValue("maxpath", 10000)
        }
    }

    return (
        <div>
            <Box fontWeight={700} fontSize={30} fontFamily={'Oswald'} letterSpacing={2}>
                <b>NEW PROJECT CONFIGURATION</b>
            </Box>
            <Box fontWeight={700} fontSize={20} fontFamily={'Oswald'} letterSpacing={2}
                 style={{display: 'flex', justifyContent: 'space-around', margin: '20px'}}>
                <Button color={'inherit'} fontFamily={'Open Sans'} style={{fontSize: "1em", border: "1px solid white"}}
                        onClick={() => {
                            setFileNumber(1)
                        }}>SINGLE SAMPLE GENESET ANALYSIS</Button>
                <Button color={'inherit'} fontFamily={'Open Sans'} style={{fontSize: "1em", border: "1px solid white"}}
                        onClick={() => {
                            setFileNumber(2)
                        }}>MULTI-SAMPLE GENESET ANALYSIS</Button>
            </Box>
            <div className="GeneralConfig">
                {fileNumber == 1 ?
                    <div style={{display: 'center'}}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {/* register your input into the hook by invoking the "register" function */}

                            <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                                <u>PROJECT TITLE</u><span>: </span>
                                <input defaultValue={"my-first-project"} {...register("title", {required: false})} />
                            </Box>
                            <br></br>
                            <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                                <u>GENESET</u><span>: </span>
                                {props.useOldGenesets ?
                                    <span><span style={{"fontWeight": "bold"}}>{"Using Previous Geneset 1"}</span>
                                    <input type="file" disabled={!props.useOldGenesets}
                                           style={{"display": "none"}} {...register("geneset1", {required: !props.useOldGenesets})} />
                                    </span>
                                    :
                                    <input type="file" disabled={props.useOldGenesets}
                                           style={{"display": "inline"}} {...register("geneset1", {required: true})} />}
                            </Box>
                            {errors.geneset1 &&
                            <Box fontWeight={300} fontSize={12} fontFamily={'Open Sans'} color={"red"}>GeneSet 1 Needs
                                to Have a File <br></br></Box>}
                            <br></br>
                            <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                                <u>PPI DATABASE</u><span>: </span>
                                <select type="file" {...register("db", {required: true})}>
                                    <option value="string">STRING</option>
                                    <option value="intact">INTACT</option>
                                    <option value="mint">MINT</option>
                                </select>
                            </Box>

                            <br></br>
                            <Button color={'inherit'} fontFamily={'Open Sans'} style={{fontSize: "1em"}}
                                    onClick={() => setShow(prev => !prev)}>↓ Advanced Settings ↓</Button>
                            <div style={{"display": (show ? "block" : "none")}}>
                                <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                                    <u>MAX PATH LENGTH</u><span>: </span>
                                    <input
                                        defaultValue={3} {...register("pathLength", {setValueAs: v => (!isNaN(parseInt(v)) && parseInt(v) > 0) ? parseInt(v) : 3})} />
                                </Box>

                                <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                                    <u>MIN INTERACTOME CONFIDENCE</u><span>: </span>
                                    <input
                                        defaultValue={0} {...register("minpath", {setValueAs: v => (!isNaN(parseInt(v)) && parseInt(v) >= 0) ? parseInt(v) : 0})} />
                                </Box>
                                <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                                    <u>MAX PATH COST</u><span>: </span>
                                    <input
                                        defaultValue={1000} {...register("maxpath", {setValueAs: v => (!isNaN(parseInt(v)) && parseInt(v) > 0) ? parseInt(v) : 200})} />
                                </Box>
                                <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                                    <u>CLUSTER THRESHOLD</u><span>: </span>
                                    <input
                                        defaultValue={0.33} {...register("threshold", {setValueAs: v => (!isNaN(parseFloat(v))) ? parseFloat(v) : 0.33})} />
                                </Box>
                                <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                                    <u>MAX VERTICES TO RENDER</u><span>: </span>
                                    <input
                                        defaultValue={50} {...register("vertices", {setValueAs: v => (!isNaN(parseInt(v)) && parseInt(v) > 0) ? parseInt(v) : 50})} />
                                </Box>
                                <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                                    <u>REPULSION CONSTANT</u><span>: </span>
                                    <input
                                        defaultValue={1.2} {...register("repulsion", {setValueAs: v => (!isNaN(parseFloat(v))) ? parseFloat(v) : 1.2})} />
                                </Box>

                                <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                                    <u>GROUP 1 ALIAS</u><span>: </span>
                                    <input defaultValue={"group1"} {...register("group1alias", {
                                        required: true,
                                        setValueAs: v => (v)
                                    })} />
                                </Box>

                            </div>


                            <br></br>

                            <AwesomeButtonProgress type={"primary"} className={"CreateProjectButton"}
                                                   element={({children, style, className}) =>
                                                       <button style={style} className={className}
                                                               type={'submit'}>{children}</button>}>
                                Create Project
                            </AwesomeButtonProgress>
                        </form>
                    </div>
                    :
                    <div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {/* register your input into the hook by invoking the "register" function */}

                            <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                                <u>PROJECT TITLE</u><span>: </span>
                                <input defaultValue={"my-first-project"} {...register("title", {required: false})} />
                            </Box>
                            <br></br>
                            <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                                <u>GENESET 1</u><span>: </span>
                                {props.useOldGenesets ?
                                    <span><span style={{"fontWeight": "bold"}}>{"Using Previous Geneset 1"}</span>
                  <input type="file" disabled={!props.useOldGenesets}
                         style={{"display": "none"}} {...register("geneset1", {required: !props.useOldGenesets})} />
                  </span>
                                    :
                                    <input type="file" disabled={props.useOldGenesets}
                                           style={{"display": "inline"}} {...register("geneset1", {required: true})} />}
                            </Box>
                            <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                                <u>GENESET 2</u><span>: </span>
                                {props.useOldGenesets ?
                                    <span><span style={{"fontWeight": "bold"}}>{"Using Previous Geneset 2"}</span>
                  <input type="file" disabled={!props.useOldGenesets}
                         style={{"display": "none"}} {...register("geneset2", {required: !props.useOldGenesets})} />
                  </span>
                                    :
                                    <input type="file" disabled={props.useOldGenesets}
                                           style={{"display": "inline"}} {...register("geneset2", {required: false})} />}
                            </Box>


                            {errors.geneset1 &&
                            <Box fontWeight={300} fontSize={12} fontFamily={'Open Sans'} color={"red"}>GeneSet 1 Needs
                                to Have a File <br></br></Box>}
                            {errors.geneset2 &&
                            <Box fontWeight={300} fontSize={12} fontFamily={'Open Sans'} color={"red"}>GeneSet 2 Needs
                                to Have a File <br></br></Box>}


                            <br></br>
                            <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                                <u>PPI DATABASE</u><span>: </span>
                                <select type="file" {...register("db", {required: true})}>
                                    <option value="string">STRING</option>
                                    <option value="intact">INTACT</option>
                                    <option value="mint">MINT</option>
                                </select>
                            </Box>

                            <br></br>
                            <Button color={'inherit'} fontFamily={'Open Sans'} style={{fontSize: "1em"}}
                                    onClick={() => setShow(prev => !prev)}>↓ Advanced Settings ↓</Button>
                            <div style={{"display": (show ? "block" : "none")}}>
                                <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                                    <u>MAX PATH LENGTH</u><span>: </span>
                                    <input
                                        defaultValue={3} {...register("pathLength", {setValueAs: v => (!isNaN(parseInt(v)) && parseInt(v) > 0) ? parseInt(v) : 3})} />
                                </Box>

                                <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                                    <u>MIN PATH COST</u><span>: </span>
                                    <input
                                        defaultValue={0} {...register("minpath", {setValueAs: v => (!isNaN(parseInt(v)) && parseInt(v) >= 0) ? parseInt(v) : 0})} />
                                </Box>
                                <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                                    <u>MAX PATH COST</u><span>: </span>
                                    <input
                                        defaultValue={1000} {...register("maxpath", {setValueAs: v => (!isNaN(parseInt(v)) && parseInt(v) > 0) ? parseInt(v) : 200})} />
                                </Box>
                                <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                                    <u>CLUSTER THRESHOLD</u><span>: </span>
                                    <input
                                        defaultValue={0.33} {...register("threshold", {setValueAs: v => (!isNaN(parseFloat(v))) ? parseFloat(v) : 0.33})} />
                                </Box>
                                <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                                    <u>MAX VERTICES TO RENDER</u><span>: </span>
                                    <input
                                        defaultValue={50} {...register("vertices", {setValueAs: v => (!isNaN(parseInt(v)) && parseInt(v) > 0) ? parseInt(v) : 50})} />
                                </Box>
                                <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                                    <u>REPULSION CONSTANT</u><span>: </span>
                                    <input
                                        defaultValue={1.2} {...register("repulsion", {setValueAs: v => (!isNaN(parseFloat(v))) ? parseFloat(v) : 1.2})} />
                                </Box>

                                <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                                    <u>GROUP 1 ALIAS</u><span>: </span>
                                    <input defaultValue={"group1"} {...register("group1alias", {
                                        required: true,
                                        setValueAs: v => (v)
                                    })} />
                                </Box>

                                <Box fontWeight={300} fontSize={14} fontFamily={'Oswald'} letterSpacing={2}>
                                    <u>GROUP 2 ALIAS</u><span>: </span>
                                    <input defaultValue={"group2"} {...register("group2alias", {
                                        required: true,
                                        setValueAs: v => (v)
                                    })} />
                                </Box>
                            </div>


                            <br></br>

                            <AwesomeButtonProgress type={"primary"} className={"CreateProjectButton"}
                                                   element={({children, style, className}) =>
                                                       <button style={style} className={className}
                                                               type={'submit'}>{children}</button>}>
                                Create Project
                            </AwesomeButtonProgress>
                        </form>
                    </div>
                }

            </div>
        </div>
    );
}

export default GeneralConfig;

