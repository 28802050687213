import axios from "axios";
import { stackOffsetSilhouette } from "d3";
import ProgressBar from "../MiscComponents/primitive/Progress";


export default async function getStatus(address, ID, setContent, statusType) {

  let imageLink =""
  let done = false;

  const toSend = {
      //cmd : "filepath dendrogram"
      cmd : "status " + statusType
  };

  let configJSON = {
      headers: {
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin': '*',
      }
  }

  console.log("GETTING STATUS:")
  console.log(address + "rich/" + ID)

  let response = await axios.post(
      address + "rich/" + ID,
      toSend,
      configJSON
  )

  let status = response.data["output"]


  //   int BARLENGTH = 15;

  // String curStatus;
  // String stepDetail;
  // Integer countStatus;
  // Integer totalCount;
  // Integer statusLayer;
  console.log(status);
  setContent(
    <div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    
    <ProgressBar 
    value={status.countStatus} 
    max={status.totalCount} //TODO: FIX BACKEND VALUES TO MAKE THIS NOT HARDCODED
    step={status.statusLayer} 
    stepDetail={status.stepDetail}
    color={(status.statusLayer == 0) ? "indianred" :"steelblue"}
  />
  </div>
  );
  // <div>
  //   <br></br>
  //   <br></br>
  //   <br></br>
    

  // </div>
  

  return status
}