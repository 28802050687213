import { GrGraphQl } from "react-icons/gr";
import {Icon} from "@mui/material"



export const Features = (props) => {
  return (
    <div id='features' className='text-center'>
      <br></br>
      <br></br>
      
      <div className='container'>
        
        <div className='col-md-12 col-md-offset-1 section-title' style={{marginLeft: '0px', marginBottom: '10px'}}>
        <p style={{marginLeft: '10%', marginRight: '10%'}}>Proteinarium is a multi-sample protein–protein interaction (PPI) tool to identify clusters
        of patients with shared networks to better understand the mechanism of complex
        diseases and phenotypes. This tool was designed to enhance the analysis of
        experimental data by identifying disease associated biological networks that define
        clusters of patients, as well as the visualization of such networks with user specified
        parameters.</p>
        <br></br>
          <h2>Features</h2>
        </div>
        <div className='section-title' style={{marginBottom: '30px'}}>
          {/* <h3><b>What is Proteinarium?:</b></h3> */}
          
          
</div>
        <div className='row'>
        
            {/* <div  className='col-xs-6 col-md-3'>
                  {' '}
                  <GrGraphQl/>
                  <h3>Cluster Target Genes into Dendrogram</h3>
                  <p>Cluster sample genes of two categories hierarchically by connectivity from an interactome of your choice. View the dendrogram that defines the clustering hierarchy for each patient. </p>
            </div> */}
            <div  className='col-xs-6 col-md-3'>
                  {' '}
                  {/* <GrGraphQl/> */}
                  <img src="Interactive_Cluster_Diagrams .png" style={{maxWidth: "30%"}}></img>
                  <h3>Interactive Dendrograms</h3>
                  <p>Click on a cluster and view the underlying layered graph, along with more info and analyses</p>
            </div>
            <div  className='col-xs-6 col-md-3'>
                  {' '}
                  <img src="Single_Paired_Cluster_Analysis .png" style={{maxWidth: "30%"}}></img>
                  <h3>Single & Paired Cluster Analysis</h3>
                  <p>Choose clusters and perform clustering coefficient analysis, or separation scoring 
<i> (Science. 2015 Feb 20;347(6224):1257601)</i> between two clusters and genes across
your cases and controls to see if these two clusters overlap in the interactome.</p>
            </div>
            <div  className='col-xs-6 col-md-3'>
                  {' '}
                  <img src="Web-based_interactive_UI.png" style={{maxWidth: "30%"}}></img>
                  <h3><b>New:</b> Web-based, interactive UI</h3>
                  <p>View Dendrograms and Clusters on the Web! Your session is saved on the cloud for future viewing. All calculations are run on the server rather than locally for ease of use. </p>
            </div>
            <div  className='col-xs-6 col-md-3'>
                  {' '}
                  <img src="additional_databases.png" style={{maxWidth: "30%"}}></img>
                  <h3><b>New:</b> Multiple Databases.</h3>
                  <p>STRING database was the default for PPI data. Now, two more
databases; MINT and INTACT are incorporated.</p>
            </div>
            
          </div>
      </div>
      <br></br>
      <br></br>
    </div>
  )
}
