

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="sponsors.png" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Acknowledgements</h2>
              <p><b>Please cite our publication if you use Proteinarium:</b>
              <br></br>
              <i className="citation">Armanious D, Schuster J, Tollefson GA, et al. Proteinarium: Multi-sample protein-protein interaction analysis and visualization tool [published online ahead of print, 2020 Jul 20]. Genomics. 2020;S0888-7543(20)30305-0. doi:10.1016/j.ygeno.2020.07.028</i></p>
              <p>Proteinarium is a collaborative project between Uzun and Schuster Labs groups, and would not have been possible without the generosity of our funding and supporting institutions:</p>
              <h3><i>The Burroughs Wellcome Fund</i></h3>
              <h3><i>Women & Infants Hospital</i></h3>
              <h3><i>Brown University</i></h3>
              <h3><i>Brown University CCV</i></h3>

            </div>
          </div>
        </div>
      </div>

    </div>
  );
};
