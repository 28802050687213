//FROM: ConfigLayout.js

import { AppBar, Box, Button, Typography, Icon, IconButton, makeStyles, createTheme, ThemeProvider, Toolbar, Tooltip, Grid } from "@material-ui/core";
// import Stack from '@material-ui/material/';


// import Dendrogram from "./Dendrogram";
// import axios from "axios";
import {useState, useEffect} from "react";
import Help from "../MiscComponents/help/Help"
import NavBar from "../MiscComponents/navbar/NavBar"
import FrontendLayout from "./Layout"
import NewProject from "../SetupComponents/NewProject"
import {useLocation} from 'react-router-dom';

import {Navigation} from "../MiscComponents/navbar/ConfigNav"
import NewProjectSample from "../SetupComponents/NewProjectSample";


function ConfigLayout(props) {

    const [showHelp, setShowHelp] = useState(false);
    const [projectInit,setProjectInit] = useState(true);

    const location = useLocation();
    console.log(location.state)

    let useOldGenesets = false
    let previousID = ""
    let previousConfig = {}
    if (location.state !== undefined && location.state !== null && location.state.UsingOldGenesets) {
        useOldGenesets = true
        previousID = location.state.id
        previousConfig = location.state.data
    }

    const useStyles = makeStyles({
        clusterbutton: {
            margin: 100,
        }
    });

    const theme = createTheme({
        palette: {
            primary: {
                main: "#EEF0F2"
            },
            secondary: {
                main: "#EEF0F2",
            },
        },
        typography: {
            fontFamily: [
                'Oswald',
                'Open Sans'
            ].join(','),
        },
    })

// https://google.github.io/material-design-icons/


    return (
        <div style={{textAlign: "center"}}>
            <ThemeProvider theme={theme}>
                <Help show={showHelp} setShow={setShowHelp} theme={theme}/>
                <Navigation/>
                {/* <NavBar
            setShowHelp={setShowHelp}
          /> */}
                {projectInit
                    ? <NewProjectSample setAnalysisType={props.setAnalysisType} bool={setProjectInit} address={props.address} setID={props.setID} idString={props.idString} setProjectName={props.setProjectName} setConfigData={props.setConfigData} useOldGenesets={useOldGenesets} previousID={previousID} previousConfig={previousConfig}/>
                    : <FrontendLayout/>}
                {/* <AwesomeButton onPress={() => setProjectInit(false)} className={"continueButton"}>Continue to Demo</AwesomeButton> */}


            </ThemeProvider>


        </div>
    );
}


export default ConfigLayout;
