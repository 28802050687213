import React, { useRef, useEffect } from 'react';
import Tree from 'react-d3-tree';
import test from './test.json';
import './D3TreeTest.css';

function D3TreeTest({ width, height, data }) {

    console.log(test)
    //let valuesArray = JSON.parse(test);
    //console.log(valuesArray)
    //<Tree data={valuesArray} />

    function click(e){
        console.log(e)
        console.log(e["data"]["name"])
    }

    const getDynamicPathClass = ({ source, target }, orientation) => {

        //if (!target.children) {
            // Target node has no children -> this link leads to a leaf node.
        //    return 'link__to-leaf';
        //}

        return source["data"]["group"]

        // Style it as a link connecting two branch nodes by default.
        return 'link__to-branch';
    };

    return (
        <div id="treeWrapper" style={{ width: '500em', height: '500em' }}>
            <Tree data={test}
                  depthFactor={-75}
                  orientation={"horizontal"}
                  nodeSize={{"x":1000, "y":70}}
                  onNodeClick={click}
                  collapsible={false}
                  separation={{"nonSiblings": 2, "siblings": 1}}
                  pathFunc={"step"}
                  rootNodeClassName="node__root"
                  branchNodeClassName="node__branch"
                  leafNodeClassName="node__leaf"
                  pathClassFunc={getDynamicPathClass}
            />
        </div>
    );
}

export default D3TreeTest;
