import { Box, Container, makeStyles, Tooltip, Icon, Grid, Typography, createTheme, ThemeProvider, Button } from "@material-ui/core";
// import { createTheme } from "@mui/material/styles";
// import AutoAwesomeMotionIcon from '@material-ui/icons-material/AutoAwesomeMotion';
import {UseState, React, useEffect }from "react";
// import MUIDataTable from "mui-datatables";
// import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import DataTable from 'react-data-table-component';
import ProgressBar from "../MiscComponents/primitive/Progress"
import getStatus from "../backendconnect/GetStatus"


const useStyles = makeStyles( bgColor => ({
  inline: {
    // display: "inline",
    // color: "#FFFFFF"
  },
  button: {
    border: '1.5px solid',
    backgroundColor: "white", //work on parameterizing later
    margin: 8,
    marginTop: 15,
  },
  innericon: {
    border: '1.5px solid',
    borderRadius: 3,
    backgroundColor: "white", //work on parameterizing later
  }
}))

const theme = createTheme({
  palette: {
    primary: {
      main: "#EEF0F2"
    },
    secondary: {
      main: "#EEF0F2",
    },
  },
  typography: {
    fontFamily: [
      'Oswald',
      'Oswald Light',
      'Open Sans'
    ].join(','),
  },
})


function genesParse(data) {
  console.log("PARSING GENES:")
  let data_list = []
  let columns = []
  if (data[0]['val1'] != undefined) {
    columns = [
      {name: 'Gene', selector: row=>row.gene, sortable: true},
      {name: 'Protein Alias', selector: row=>row.proteins, sortable: true},
      {name: 'Cluster ID', selector: row=>row.cid, sortable: true}
    ]
    data.forEach((row,i) => {
      data_list.push({id: i, gene: row['val0']['symbol'], proteins: row['val0']['proteins'].map(e => e.id).toString(), cid: row['val1']})
    })
  } else {
    columns = [
      {name: 'Gene', selector: row=>row.gene, sortable: true},
      {name: 'Protein Alias', selector: row=>row.proteins, sortable: true}
    ]
    console.log(columns)
    data.forEach((row,i) => {
      data_list.push({id: i, gene: row['symbol'], proteins: row['proteins'].map(e => e.id).toString()})
    })
  }
  
  return [data_list, columns]
}

function toJSONObj(data) {
  try {
    return JSON.parse(data)
  } catch (e) {
    return data
  }
}
function weightsParse(data) {
  let data_list = []
  let columns = []
  if (data[0]['val3'] != undefined) {
    columns = [
      {name: 'Gene 1', selector: row=>row.gene1, sortable: true},
      {name: 'Gene 2', selector: row=>row.gene2, sortable: true},
      {name: 'Interactome Score', selector: row=>row.score, sortable: true},
      {name: 'Cluster ID', selector: row=>row.cid, sortable: true}
    ]
    data.forEach((row,i) => {
      data_list.push({id: i, gene1: row['val0']['symbol'], gene2: row['val1']['symbol'], score: row['val2'], cid: row['val3']})
    })}
  else {
    columns = [
      {name: 'Gene 1', selector: row=>row.gene1, sortable: true},
      {name: 'Gene 2', selector: row=>row.gene2, sortable: true},
      {name: 'Interactome Score', selector: row=>row.score, sortable: true}
    ]
    data = toJSONObj(data)
    data.forEach((row,i) => {
      data_list.push({id: i, gene1: row['val0']['symbol'], gene2: row['val1']['symbol'], score: row['val2']})
    })
  }
  return [data_list, columns]
}

function centralityParse(data) {
  let data_list = []
  let columns = []
  console.log()
  if (data[0]['val4'] != undefined) {
    columns = [
      {name: 'Gene ID', selector: row=>row.gene, sortable: true},
      {name: 'Degree Score', selector: row=>row.degree, sortable: true},
      {name: 'Closeness Score', selector: row=>row.closeness, sortable: true},
      {name: 'Betweenness Score', selector: row=>row.betweenness, sortable: true},
      {name: 'Cluster ID', selector: row=>row.cid, sortable: true}
    ]
    data.forEach((row,i) => {
      data_list.push({id: i, gene: row['val0']['symbol'], degree: row['val1'], closeness: row['val2'], betweenness: row['val3'], cid: row['val4']})
    })
  } else {
    columns = [
      {name: 'Gene ID', selector: row=>row.gene, sortable: true},
      {name: 'Degree Score', selector: row=>row.degree, sortable: true},
      {name: 'Closeness Score', selector: row=>row.closeness, sortable: true},
      {name: 'Betweenness Score', selector: row=>row.betweenness, sortable: true}
    ]
    data.forEach((row,i) => {
      data_list.push({id: i, gene: row['val0']['symbol'], degree: row['val1'], closeness: row['val2'], betweenness: row['val3']})
    })
  }
  
  
  return [data_list, columns]
}

function CommandButton(props) {

  //console.log(props)
  let hasFinished = false
  let setDesc = function() {
    //EDIT:
    props.setRespLoad(false)
    console.log("RESPLOAD SET TO FALSE")

    // console.log(props.cmd)
    // console.log(props.cID)
    // console.log(props.setInfo)
    if (props.isTable) { //JSON UNPACK FOR DATA
      props.backendCMD(props.cmd, props.cID).then((data) => {

        // console.log(obj)
        // console.log(obj[1])
        let data_list;
        let columns;
        if (props.cmd === "genes") {
          [data_list,columns] = genesParse(data)
        } else if (props.cmd === "weights") {
          [data_list,columns] = weightsParse(data)
        } else if (props.cmd === "topcentrality") {
          [data_list,columns]  = centralityParse(data)
        }
        
        console.log(data_list)
        console.log(columns)

        hasFinished = true
        props.setClickedButton(props.title)

        //EDIT:
        props.setRespLoad(true)
        console.log("RESPLOAD SET TO TRUE")

        props.setInfo(
          <DataTable
            data={data_list}
            columns={columns}
            direction="auto"
            dense
            highlightOnHover
            fixedHeaderScrollHeight="300px"
            pagination
            responsive
            subHeaderAlign="right"
            subHeaderWrap
        />)
      })
    }
    else { // UNPACK REGULAR STRING
      props.backendCMD(props.cmd, props.cID).then((desc) => {
        console.log("NEW DESC: " + desc)
        props.setInfo(desc)
        hasFinished = true
        //EDIT:
        props.setRespLoad(true)
        console.log("RESPLOAD SET TO TRUE")
        props.setClickedButton(props.title)
      })
    }

    // alert(props.cmd)
    if (props.cmd === "sep") { //LOADING BAR FOR JUST SEPARATION (for now)
      
      // useEffect(() => {
        let statusWait = setInterval(() => {
          
          getStatus(props.address, props.id, props.setInfo, "septest").then((stat) => {
            if (stat.countStatus >= stat.totalCount || hasFinished) {
              clearInterval(statusWait);
              props.backendCMD(props.cmd, props.cID).then((desc) => {
                console.log("NEW DESC: " + desc)
                props.setInfo(desc)
                hasFinished = true
              })
            }
          })
      }, 1000)
    // })
    
    }

    
  }
//H
  const classes = useStyles("grey");
  const containerStyle = {
    backgroundColor: props.clickedButton === props.title ? '#d8d8d8' : 'white',
  };

  const body = <Button className={classes.button} onClick={setDesc} style={containerStyle}>
  {/*<Grid item xs={6}>*/}
              <Box  textAlign={"center"} className={classes.inline} fontWeight={600} fontSize={15} fontFamily={'Oswald'} letterSpacing={0.5} >
                  {props.title}
              </Box>
          {/*</Grid>*/}
        </Button>

  const awaitingResp = <Button className={classes.button} style={containerStyle} disabled>
    {/*<Grid item xs={6}>*/}
    <Box  textAlign={"center"} className={classes.inline} fontWeight={600} fontSize={15} fontFamily={'Oswald'} letterSpacing={0.5} >
      {props.title}
    </Box>
    {/*</Grid>*/}
  </Button>
  return (
    <ThemeProvider theme={theme}>
        {props.respLoad ? body : awaitingResp}
    </ThemeProvider>
  );
}

export default CommandButton;