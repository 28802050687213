import * as d3 from 'd3'
import React, {useEffect, useRef, useState} from 'react'
import test from './archive/test.json';
import {ForceGraph2D} from 'react-force-graph';
import axios from "axios";
import Slider from "@mui/material/Slider";
import {Box} from "@material-ui/core";

export async function getClusterLinksMap(address, ID, cluster) {
    let new_render_data = []

    const toSend = {cmd: "render " + cluster};
    let configJSON = {
        headers: {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
        }
    }
    const response = await axios.post(
        address + "rich/" + ID,
        toSend,
        configJSON
    );


    const linkMap = await response.data["output"]

    if (linkMap !== "RENDER ERROR: malformed input (only include ONE VALID CLUSTER ID!)") {

        //console.log(linkMap)
        //let renders = Object.keys(linkMap)
        for (let k in linkMap) {
            let currLink = linkMap[k]
            let idx = currLink.indexOf("output")
            currLink = currLink.substring(idx + 6, currLink.length)

            if (address.includes("proteinarium")) {
                let new_address = address.substring(0, address.length - 5) + "/Proteinariumweb/output"
                currLink = new_address + currLink
            } else {
                currLink = address.substring(0, address.length - 5) + "8887" + currLink
            }

            //"http://127.0.0.1:8887/" + currLink

            let render = await fetch(currLink);
            new_render_data[k] = await render.json()
        }
        return new_render_data
    }
    //console.log(new_render_data)
    return new_render_data
}

function ClusterRender(props) {

    console.log(props)
    let new_render_data = props.data

    //const [currRender, setCurrRender] = useState(0);

    async function getClusterLinks(address, ID) {

        let imageLinks = []
        let clusterTypes = ["Group1", "Group2", "Group1AndGroup2", "Group1MinusGroup2", "Group2MinusGroup1"]

        for (let t in clusterTypes) {
            await getJSON(t).then((link) => {
                let linkObj = {t:link}
                imageLinks.push(linkObj)
            })
        }

        return imageLinks

        function getJSON(clusterType) {

            const toSend = {cmd: "filepath " + clusterType};
            let configJSON = {
                headers: {
                    "Content-Type": "application/json",
                    'Access-Control-Allow-Origin': '*',
                }
            }
            return axios.post(
                address + "rich/" + ID,
                toSend,
                configJSON
            ).then(response => {
                //console.log(response.data["output"]);

                let link = response.data["output"]
                if (link !== "FILEPATH ERROR: FILE NOT FOUND WITH KEYWORD") {

                    let idx = link.indexOf("output")
                    link = link.substring(idx + 6, link.length)
                    link = "http://127.0.0.1:8887/" + link
                    //resolve(link)
                    return link
                }
            })
        }
    }

    function groupColorScheme(node) {

        let genes = []
        if (props.paired) {
            genes = uniqueGenes()
        }

        let LOWER_ALPHA_BOUND = 0.2;
        let UPPER_ALPHA_BOUND = 0.8;
        let keyNames = Object.keys(new_render_data)
        let maxCount = new_render_data[keyNames[props.currRender]].max_count;
        //console.log(props.sharedMaxCount)
        if (props.sharedMaxCount !== null && props.sharedMaxCount !== undefined) {
            if ((typeof props.sharedMaxCount.current) === "number") {
                if (props.sharedMaxCount > maxCount) {
                    maxCount = props.sharedMaxCount.current
                } else {
                    props.sharedMaxCount.current = maxCount
                }
            } else {
                props.sharedMaxCount.current = maxCount
            }
        }
        let currCount = node.count;
        let alphaVal = (LOWER_ALPHA_BOUND + (UPPER_ALPHA_BOUND - LOWER_ALPHA_BOUND) * currCount / maxCount);
        if (genes.includes(node["id"])) {
            return "purple"
        } else if (node.group === "group1") {
            return `rgba(255, 200, 0, ${alphaVal})` //yellow
        } else if (node.group === "group2") {
            return `rgba(0, 0, 255, ${alphaVal})` //blue
        } else if (node.group === "both") {
            return `rgba(0, 255, 0, ${alphaVal})` //green
        } else if (node.group === "imputed") {
            return `rgba(255, 0, 0, ${alphaVal})` //red
        } else {
            return "red"
        }
    }

    function paintNode({gene, x,y, count}, color, ctx) {
        ctx.beginPath();
        let r = chooseNodeSize(count)
        ctx.arc(x, y, r, 0, 2 * Math.PI, false);
        ctx.fillStyle = color;
        ctx.fill();
        ctx.fillStyle = "black";
        ctx.font = '8px Trebuchet MS';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(gene, x, y);
    }

    function chooseNodeSize(count) {
        let LOWER_ALPHA_BOUND = 5;
        let UPPER_ALPHA_BOUND = 15;
        let keyNames = Object.keys(new_render_data)
        let maxCount = new_render_data[keyNames[props.currRender]].max_count;
        let currCount = count;
        let r = (LOWER_ALPHA_BOUND + (UPPER_ALPHA_BOUND - LOWER_ALPHA_BOUND) * currCount / maxCount);
        return r
    }

    function uniqueGenes() {
        let genes = []
        //console.log(props.data)
        let keyNamesOne = Object.keys(props.data)
        let keyNamesTwo = Object.keys(props.dataSec)
        let currData = props.data[keyNamesOne[props.currRender]]["graph"]["nodes"]
        let secData = props.dataSec[keyNamesTwo[props.currRenderSec]]["graph"]["nodes"]

        for (let n in currData) {
            let currNode = currData[n]
            if (!secData.map(v => v["id"]).includes(currNode["id"])) {
                genes.push(currNode["id"])
            }
        }

        //console.log(genes)
        return genes
    }

    // Group1, Group2, Group1AndGroup2, Group1MinusGroup2, Group2MinusGroup1

    const fgRef = useRef();
    //console.log(props)

    const curClusterView = useRef("");

    //console.log(getClusterLinksMap(props.address, props.id, "C1"))

    let currGraph = <p>cluster not loaded</p>;

    if (new_render_data !== undefined && new_render_data !== null && Object.keys(new_render_data).length > 0) {
        let keyNames = Object.keys(new_render_data)
        console.log(new_render_data)
        //console.log(keyNames)
        //console.log("GOT HERE")
        console.log(props.currRender)
        // if (currRender >= keyNames.length) {
        //     console.log("RESET!")
        //     setCurrRender(0)
        // }
        // else if (currRender < 0) {
        //   setCurrRender(keyNames.length)
        // }
        /*
        width={props.width}
                height={props.height - 100}
         */

        if (new_render_data[keyNames[props.currRender]] !== undefined) {
            console.log("Found a Graph")
            console.log(new_render_data[keyNames[props.currRender]])
            curClusterView.current = keyNames[props.currRender]
            currGraph = <ForceGraph2D
                width={props.width}
                height={props.height}
                onNodeClick={(e) => console.log(e)}
                nodeColor={groupColorScheme}
                graphData={new_render_data[keyNames[props.currRender]]["graph"]}
                nodeCanvasObject={(node, ctx) => paintNode(node, groupColorScheme(node), ctx)}
                nodeVal={(node) => chooseNodeSize(node.count)}
                linkColor={() => "rgba(93,93,93,1)"}
                linkWidth={() => 1.5}
                ref={fgRef}
                cooldownTicks={150}
                onEngineStop={() => {
                    if (fgRef !== null && fgRef !== undefined) {
                        fgRef.current.zoomToFit(400)
                        //fgRef.current.d3Force('charge', d3.forceManyBody().strength(0).distanceMax(1000));
                    }
                }}
            />

            //const fg = fgRef.current;
            // Deactivate existing forces
            //fg.d3Force('charge', d3.forceManyBody().strength(-10));
        }
    }

    useEffect(() => {
        //fgRef.current = "";
        curClusterView.current = "";


        const fg = fgRef.current
        console.log(fg)
        if (fg !== undefined && fg !== null) {
            fg.d3Force('charge', d3.forceManyBody().strength(-30).distanceMax(1000));
            fg.d3Force('collide', d3.forceCollide().strength(1).radius(n => {
                return chooseNodeSize(n.count)
            }))
            //fg.d3Force('link', d3.forceLink().distance(30));
            //fg.d3Force('center', d3.forceCenter().strength(30))
            fg.d3ReheatSimulation()
        }

        //const fg = fgRef.current;
        // Deactivate existing forces
        //fg.d3Force('charge', d3.forceManyBody().strength(-10));

    }, [props.currRender]);

    /*
    {currRender > 0 ? <button onClick={() => {setCurrRender(currRender - 1)}}>&lt;</button> : <div></div>}
        {curClusterView.current}
        {currRender < Object.keys(new_render_data).length - 1 ? <button onClick={() => {setCurrRender(currRender + 1)}}>&gt;</button> : <div></div>}
     */

    console.log(props.toggleForceSliderHidden.current)
    let sliderBox = <Box display={"flex"} flexDirection={"column"}>
        <Slider defaultValue={-30} aria-label="Default" valueLabelDisplay="auto" min={-60} max={0} onChange={(e) => {
            const fg = fgRef.current
            if (fg !== undefined && fg !== null) {
                //console.log(e.target.value)
                fg.d3Force('charge', d3.forceManyBody().strength(e.target.value))
                    //.distanceMin(15).distanceMax(1000));
                //fg.d3Force('link', d3.forceLink().distance(30));
                fg.d3ReheatSimulation()
            }
        }}/>
        {/*<p>Adjust Force</p>*/}
    </Box>

    if (!props.toggleForceSliderHidden.current) {
       sliderBox = <div></div>
    }

    return (
        <div>
            {/* <button onClick={() => {setCurrRender(currRender - 1)}}>&gt;</button> */}
            {currGraph}
            {sliderBox}
        </div>
    )
}

export default ClusterRender;