import axios from "axios";


export default async function filePath(address, ID, keyReceived) {

  let imageLink =""
  let done = false;

  console.log("filepath " + keyReceived)

  const toSend = {
      //cmd : "filepath dendrogram"
      cmd : "filepath " + keyReceived
  };

  let configJSON = {
      headers: {
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin': '*',
      }
  }

  console.log("oh no")
  console.log(address + "rich/" + ID)

  let response = await axios.post(
      address + "rich/" + ID,
      toSend,
      configJSON
  )

    // console.log("HIII IM HERE ")

  let link = response.data["output"]

    //commented out following to try and get single sample working...
  // if (link !== "FILEPATH ERROR: FILE NOT FOUND WITH KEYWORD d3json") {

      // console.log("NO ERROR")

    //EDIT ADDED 9/1
    if (link === "ERROR: Graph Empty") {
        return link
    }


      //http://127.0.0.1:8887
      let idx = link.indexOf("output")
      link = link.substring(idx + 6, link.length)
      if (address.includes("proteinarium")) {
          address = address.substring(0, address.length - 5) + "/Proteinariumweb/output"
          link = address + link
      } else {
          link = address.substring(0, address.length - 5) + "8887" + link
      }
      //"http://127.0.0.1:8887/" + link
      console.log(link)

      imageLink = link;

  // }

  return imageLink
}