
import Command from '../../archive/backendconnect/Command.js'
import CommandRich from '../../archive/backendconnect/CommandRich.js'
import ConfigSetup from "./ConfigSetup";
import {Field, Form} from "easy-react-form";

function AnalysisConfig() {
  return (
    <div className="AnalysisConfig">
      <div>Analysis Configuration</div>

      <Form onSubmit={ values => console.log(values) }>

        <div className={"InputPair"}>
          <label htmlFor="minInteractomeConfidence" className={"pairFlex"}>Min Interactome Confidence</label>
          <Field
              name="minInteractomeConfidence"
              component="input"
              type="text"
              placeholder="Integer between 0 and 1000"
              className={"pairFlex"}/>
        </div>

        <div className={"InputPair"}>
          <label htmlFor="maxPathCost" className={"pairFlex"}>Max Path Cost</label>
          <Field
              name="maxPathCost"
              component="input"
              type="text"
              placeholder="Default: 200"
              className={"pairFlex"}/>
        </div>

        <div className={"InputPair"}>
          <label htmlFor="maxPathLength" className={"pairFlex"}>Max Path Length</label>
          <Field
              name="maxPathLength"
              component="input"
              type="text"
              placeholder="Default: 5"
              className={"pairFlex"}/>
        </div>

        <div className={"InputPair"}>
          <label htmlFor="fractionOfVerticesToRender" className={"pairFlex"}>Fraction of Vertices To Render</label>
          <Field
              name="fractionOfVerticesToRender"
              component="input"
              type="text"
              placeholder="Default: 1"
              className={"pairFlex"}/>
        </div>

        <div className={"InputPair"}>
          <label htmlFor="maxVerticesToRender" className={"pairFlex"}>Max Vertices To Render</label>
          <Field
              name="maxVerticesToRender"
              component="input"
              type="text"
              placeholder="Default: 2147483647"
              className={"pairFlex"}/>
        </div>

        <div className={"InputPair"}>
          <label htmlFor="bootstrappingRounds" className={"pairFlex"}>Boot Strapping Rounds</label>
          <Field
              name="bootstrappingRounds"
              component="input"
              type="text"
              placeholder="Default: 1000"
              className={"pairFlex"}/>
        </div>



      </Form>
      
    </div>
  );
}

export default AnalysisConfig;
