import {
    AppBar,
    Box,
    Button,
    Typography,
    Icon,
    IconButton,
    makeStyles,
    createTheme,
    ThemeProvider,
    Toolbar,
    Tooltip,
    Grid,
    Modal,
    Dialog,
    DialogActions
} from "@material-ui/core";
// import Stack from '@material-ui/material/'; 

//import { Modal } from '@mui/material';
// import Dendrogram from "./Dendrogram";
// import axios from "axios";
import React, {useState, useEffect, useRef} from "react";
import Help from "../MiscComponents/help/Help"
import NavBar from "../MiscComponents/navbar/NavBar"
import DendrogramBox from "../DendrogramPane/DendrogramBox"
import Dendrogram from "../DendrogramPane/Dendrogram";
import InfoBox from "../InfoPane/InfoBox";
// import InfoBox from "../components/content/InfoBox"
import ClusterButton from "../ClusterPane/ClusterButton"

import axios from "axios";
import ClusterBox from "../ClusterPane/ClusterBox";
import ClusterRender, {getClusterLinksMap} from "../ClusterPane/ClusterRender";
import key from '../ClusterPane/key.png';
import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';
import html2canvas from 'html2canvas';
import filePath from "../backendconnect/FilePath";
import { saveAs } from "file-saver";


import './bootstrap.min.css';

function FrontendLayout(props) {

    // STYLE PROPERTIES
    const useStyles = makeStyles({
        clusterbutton: {
            margin: 100,
        },
        paper: {
            overflowY: 'unset',
        },
        customizedButton: {
            width: 40,
            height: 40,
            position: 'absolute',
            left: '96%',
            top: '1%',
            backgroundColor: 'lightgray',
            color: 'gray',
        }
    });
    const theme = createTheme({
        palette: {
            primary: {
                main: "#EEF0F2"
            },
            secondary: {
                main: "#EEF0F2",
            },
        },
        typography: {
            fontFamily: [
                'Oswald',
                'Open Sans'
            ].join(','),
        },
    });
    const classes = useStyles();

    const currClicked = useRef([]);
    const buttonList = useRef([]);

    const [infoDesc, setInfoDesc] = useState("");
    const [denDesc, setDenDesc] = useState("");
    const [clusterChanged, setClusterChanged] = useState(false);
    const [clusterData, setClusterData] = useState();
    const [modalData, setModalData] = useState();

    const [idUsed, setCID] = useState("")
    const [paired, setPaired] = useState(false);

    const sharedMaxCount = useRef();
    const screenshotModalView = useRef(false);
    const cachedClusterDiagrams = useRef({});

    const uniqueGeneColorToggled = useRef(true);
    const toggleForceSliderHidden = useRef(true);

    async function getInfo(address, cID, ID) {

        let desc = ""
        await infoReq().then((info) => {
            //console.log("INFO IN AWAIT: " + info)
            desc = info
        })

        return desc

        // TO CALL: await getInfo(props.address, clusterID)
        function infoReq() {

            const toSend = {cmd: "info " + cID};
            let configJSON = {
                headers: {
                    "Content-Type": "application/json",
                    'Access-Control-Allow-Origin': '*',
                }
            }
            return axios.post(
                address + "rich/" + ID,
                toSend,
                configJSON
            ).then(response => {
                //console.log(response.data["output"]);

                let info = response.data["output"]
                if (info !== null) {
                    //TODO: split by line of information
                    return info
                }
            })
        }
    }

    function getInfoByCmd(ID, address) {
        return async (cmd, cID) => {
            let desc = ""
            await infoReq().then((info) => {
                //console.log("INFO IN AWAIT: " + info)
                desc = info
            })

            return desc

            // TO CALL: await getInfo(props.address, clusterID)
            function infoReq() {

                const toSend = {cmd: cmd + " " + cID};
                let configJSON = {
                    headers: {
                        "Content-Type": "application/json",
                        'Access-Control-Allow-Origin': '*',
                    }
                }
                return axios.post(
                    address + "rich/" + ID,
                    toSend,
                    configJSON
                ).then(response => {
                    //console.log(response.data["output"]);

                    let info = response.data["output"]
                    if (info !== null) {
                        //TODO: split by line of information
                        return info
                    }
                })
            }
        }
    }

    function showClusterInfo(cID) {
        //console.log(cID);
        console.log("cid is", cID)
        setCID(cID)
        const cluster = Math.floor(Math.random() * 300)
        const pval = Math.random()
        let desc = ""
        getInfo(props.address, cID, props.id).then((desc) => {
                //console.log("NEW DESC: " + desc)
                desc.split('\n').map((line, i) => (
                    <span key={i}><br/></span>))
                desc = desc.substring(desc.indexOf("\n") + 1, desc.length - 1)
                setInfoDesc(desc)
            }
        )
    }

    function handleClusterClickCurry(id, address) {
        return (cID) => {
            setCID(cID)
            console.log("SUCCESSFUL CURRY: ")
            console.log("ID: " + id)
            //getClusterLinksMap(address, id, cID)
            addClusterButton(cID)
            focusCluster(id, address, cID)
        }
    }

    function focusCluster(id, address, cID) {
        let pairVal = false;
        if (cID.includes("<=>")) {
            //setPaired(true);
            pairVal = true;
        }

        showClusterInfo(cID)
        showClusterDiagrams(address, cID, id, pairVal)
    }

    function addClusterButton(cID) {
        let checkForMatches = buttonList.current.filter((val) => {
            return val.props.clusterNum !== cID
        }).length
        if (checkForMatches === buttonList.current.length) {
            // updateButtonList(arr => arr.unshift(<ClusterButton className={classes.clusterbutton} onPress={addClusterButton} clusterNum={"NEW"} bgColor={"red"} iconUsed={"playlist_add"}>hi</ClusterButton>))
            //const cID = "C" + Math.floor(Math.random()*30)
            console.log("ADD CLUSTER BUTTON: " + cID);
            //updateButtonList(arr => [<ClusterButton className={classes.clusterbutton} onPress={focusCluster(cID)} clusterNum={cID} bgColor={"grey"} iconUsed={"view_quilt"}>hi</ClusterButton>, ...arr]);
            let iReverse = buttonList.current.length
            console.log("CLUSTER BUTTON LIST LEN: " + buttonList.current.length)
            console.log(buttonList)

            let NewButton =
                <ClusterButton
                    i={iReverse}
                    className={classes.clusterbutton}
                    onPress={() => focusCluster(props.id, props.address, cID)} //has to be annoymous function here so it does not run until press
                    onRemove={removeClusterButton}
                    clusterNum={cID}
                    bgColor={"grey"}
                    iconUsed={"close"}>hi</ClusterButton>

            //arr => [NewButton, ...arr]
            //[NewButton].concat(buttonList)

            //updateButtonList(arr => [NewButton, ...arr]);
            buttonList.current = [NewButton].concat(buttonList.current)
            setClusterChanged(true);
            clusterSaveCommand(props.address, props.id, cID, "")
            return true;
        }
        return false;
    }

    function removeClusterButton(cID) {
        //let removeI = ((buttonList.current.length - i))
        //console.log("REMOVING ELEMENT: " + removeI)
        //console.log("PREV LIST LEN: " + buttonList.current.length)
        //updateButtonList(buttonList.splice(buttonList.length - i, 1));
        //updateButtonList(buttonList.splice(buttonList.indexOf(i), 1));
        //buttonList.current = buttonList.current.slice(0,removeI)
        //    .concat(buttonList.current.slice(removeI+1, buttonList.current.length))
        //buttonList.current.splice((buttonList.current.length - i) - 1, 1)

        //console.log(buttonList.current)
        //console.log(cID)
        buttonList.current = buttonList.current.filter((val) => {
            return val.props.clusterNum !== cID
        })
        //console.log("NEW LIST LEN: " + buttonList.current.length)
        setClusterChanged(true);
        clusterSaveCommand(props.address, props.id, "", cID)
    }

    const currRenderOne = useRef(0);
    const currRenderTwo = useRef(0)
    const [currClusterView, setCurrClusterView] = useState();

    const modalRef = useRef();

    function showClusterDiagrams(address, cID, id, paired) {
        currRenderOne.current = 0
        currRenderTwo.current = 0
        setCurrClusterView(<div></div>)

        if (!paired && cID.trim() !== "") {
            if (cID in cachedClusterDiagrams.current) {
                setClusterRenderSingle(address, cID, id, cachedClusterDiagrams.current[cID])
            } else {
                getClusterLinksMap(address, id, cID).then((new_data) => {

                    cachedClusterDiagrams.current[cID] = new_data
                    setClusterRenderSingle(address, cID, id, new_data)
                })
            }
        } else if (cID.trim() !== "") {
            let split = cID.split('<=>');
            let id1 = split[0].trim();
            let id2 = split[1].trim();

            if (id1 in cachedClusterDiagrams.current && id2 in cachedClusterDiagrams.current) {
                setClusterRenderPaired(address, id1, id2, id, true, cachedClusterDiagrams.current[id1], cachedClusterDiagrams.current[id2])
            } else if (id1 in cachedClusterDiagrams.current) {
                    getClusterLinksMap(address, id, id2).then((new_data2) => {

                        cachedClusterDiagrams.current[id2] = new_data2
                        setClusterRenderPaired(address, id1, id2, id, true, cachedClusterDiagrams.current[id1], new_data2)
                    })
            } else if (id2 in cachedClusterDiagrams.current) {
                getClusterLinksMap(address, id, id2).then((new_data1) => {

                    cachedClusterDiagrams.current[id1] = new_data1
                    setClusterRenderPaired(address, id1, id2, id, true, new_data1, cachedClusterDiagrams.current[id2])
                })
            } else {
                getClusterLinksMap(address, id, id1).then((new_data1) => {
                    getClusterLinksMap(address, id, id2).then((new_data2) => {

                        /*
                        let match_data1 = {}
                        let match_data2 = {}
                        for (let key1 in Object.keys(new_data1)) {
                            let currKey = Object.keys(new_data1)[key1]
                            console.log(currKey)
                            if (Object.keys(new_data2).includes(currKey)) {
                                match_data1[currKey] = new_data1[currKey]
                                match_data2[currKey] = new_data2[currKey]
                            }
                        }
                        console.log(match_data1)
                        console.log(match_data2)
                        */

                        console.log(new_data1)
                        console.log(new_data2)
                        // MAKE SURE TO ERROR CHECK HERE IF ONE OF THEM IS NULL
                        // TODO fix this

                        cachedClusterDiagrams.current[id1] = new_data1
                        cachedClusterDiagrams.current[id2] = new_data2

                        setClusterRenderPaired(address, id1, id2, id, true, new_data1, new_data2)
                        console.log("GOT DATA PAIRED")
                    })
                })
            }
        }

        function changeClusterRenderSingle(address, cID, id, new_data, change) {
            currRenderOne.current = currRenderOne.current + change

            setClusterRenderSingle(address, cID, id, new_data)
        }

        function setClusterRenderSingle(address, cID, id, new_data) {
            console.log(new_data)
            console.log(currRenderOne.current)
            setClusterData(<div><Box id={"pairedBox"} display={"flex"} flexDirection={"row"} justifyContent={"center"}>
                {/*style={{"margin": "auto"}}*/}
                <div>
                    {currRenderOne.current > 0 ? <button onClick={() => {changeClusterRenderSingle(address, cID, id, new_data, -1)}}>&lt;</button> : <div></div>}
                    {groupAlias(Object.keys(new_data)[currRenderOne.current])}
                    {currRenderOne.current < Object.keys(new_data).length - 1 ? <button onClick={() => {changeClusterRenderSingle(address, cID, id, new_data, 1)}}>&gt;</button> : <div></div>}

                    <div style={{"border": "thin solid", "borderRadius": "8px"}}>
                        <ClusterRender address={address} data={new_data} paired={paired} currRender={currRenderOne.current} id={id} width={300} height={300} toggleForceSliderHidden={toggleForceSliderHidden}/>
                    </div>
                </div>
                {returnClusterKey()}
            </Box>
                <Box display={"flex"} flexDirection={"row"} justifyContent={"center"}>
                    <label>Toggle Force Slider</label>
                    <input type={"checkbox"} checked={toggleForceSliderHidden.current} onChange={(e) => {
                        toggleForceSliderHidden.current = e.target.checked
                        setClusterRenderSingle(address, cID, id, new_data)
                    }}/>
                </Box>
            </div>)

            console.log(screenshotModalView.current, "value")
            if (!screenshotModalView.current) {

                setModalData(<div><Box id={"pairedBox"} display={"flex"} flexDirection={"row"} justifyContent={"center"}>
                    {/*style={{"margin": "auto"}}*/}

                    <div>
                        {currRenderOne.current > 0 ? <button onClick={() => {changeClusterRenderSingle(address, cID, id, new_data, -1)}}>&lt;</button> : <div></div>}
                        {Object.keys(new_data)[currRenderOne.current]}
                        {currRenderOne.current < Object.keys(new_data).length - 1 ? <button onClick={() => {changeClusterRenderSingle(address, cID, id, new_data, 1)}}>&gt;</button> : <div></div>}
                        <div style={{"border": "thin solid", "borderRadius": "8px"}}>
                            <ClusterRender address={address} data={new_data} paired={paired} currRender={currRenderOne.current} id={id} width={500} height={500} toggleForceSliderHidden={toggleForceSliderHidden}/>
                        </div>
                    </div>
                </Box>
                    <Box display={"flex"} flexDirection={"row"} justifyContent={"center"}>
                        <label>Toggle Force Slider</label>
                        <input type={"checkbox"} checked={toggleForceSliderHidden.current} onChange={(e) => {
                            toggleForceSliderHidden.current = e.target.checked
                            setClusterRenderSingle(address, cID, id, new_data)
                        }}/>
                    </Box>
                </div>)
            } else {
                screenshotModalSingle(address, cID, id, new_data)
            }
        }

        function changeClusterRenderPaired(address, id1, id2, id, paired, match_data1, match_data2, change, renderNum) {
            if (renderNum === 1) {
                currRenderOne.current = currRenderOne.current + change
            } else if (renderNum === 2) {
                currRenderTwo.current = currRenderTwo.current + change
            } else {
                console.log("Error found here, check this line")
            }

            setClusterRenderPaired(address, id1, id2, id, paired, match_data1, match_data2)
        }

        function setClusterRenderPaired(address, id1, id2, id, paired, match_data1, match_data2) {
            setClusterData(<div>
                <Box id={"pairedBox"} display={"flex"} flexDirection={"row"}>
                    <div>
                        {currRenderOne.current > 0 ? <button onClick={() => {changeClusterRenderPaired(address, id1, id2, id, paired, match_data1, match_data2, -1, 1)}}>&lt;</button> : <div></div>}
                        {groupAlias(Object.keys(match_data1)[currRenderOne.current])}
                        {currRenderOne.current < Object.keys(match_data1).length - 1 ? <button onClick={() => {changeClusterRenderPaired(address, id1, id2, id, paired, match_data1, match_data2, 1, 1)}}>&gt;</button> : <div></div>}
                        <div style={{"border": "thin solid", "borderRadius": "8px"}}>
                            <ClusterRender address={address} cID={id1} paired={paired} data={match_data1} dataSec={match_data2} currRender={currRenderOne.current} currRenderSec={currRenderTwo.current} sharedMaxCount={sharedMaxCount} id={id} width={250} height={250} toggleForceSliderHidden={toggleForceSliderHidden}/>
                        </div>
                    </div>
                    <div>
                        {currRenderTwo.current > 0 ? <button onClick={() => {changeClusterRenderPaired(address, id1, id2, id, paired, match_data1, match_data2, -1, 2)}}>&lt;</button> : <div></div>}
                        {groupAlias(Object.keys(match_data2)[currRenderTwo.current])}
                        {currRenderTwo.current < Object.keys(match_data2).length - 1 ? <button onClick={() => {changeClusterRenderPaired(address, id1, id2, id, paired, match_data1, match_data2, 1, 2)}}>&gt;</button> : <div></div>}
                        <div style={{"border": "thin solid", "borderRadius": "8px"}}>
                            <ClusterRender address={address} cID={id2} paired={paired} data={match_data2} dataSec={match_data1} currRender={currRenderTwo.current} currRenderSec={currRenderOne.current} sharedMaxCount={sharedMaxCount} id={id} width={250} height={250} toggleForceSliderHidden={toggleForceSliderHidden}/>
                        </div>
                    </div>
                    {returnClusterKey()}
                </Box>
                <Box display={"flex"} flexDirection={"row"} justifyContent={"center"}>
                    <label>Toggle Unique Gene Coloring</label>
                    <input type={"checkbox"} checked={uniqueGeneColorToggled.current} onChange={(e) => {
                        uniqueGeneColorToggled.current = e.target.checked
                        setClusterRenderPaired(address, id1, id2, id, e.target.checked, match_data1, match_data2)
                    }}/>
                </Box>
                <Box display={"flex"} flexDirection={"row"} justifyContent={"center"}>
                    <label>Toggle Force Slider</label>
                    <input type={"checkbox"} checked={toggleForceSliderHidden.current} onChange={(e) => {
                        toggleForceSliderHidden.current = e.target.checked
                        setClusterRenderPaired(address, id1, id2, id, uniqueGeneColorToggled.current, match_data1, match_data2)
                    }}/>
                </Box>

            </div>)

            console.log("SCREENSHOTVIEW")
            console.log(screenshotModalView.current)
            if (!screenshotModalView.current) {
                setModalData(<div>
                    <Box id={"pairedBox"} display={"flex"} flexDirection={"row"}>
                        <div>
                            {currRenderOne.current > 0 ? <button onClick={() => {
                                changeClusterRenderPaired(address, id1, id2, id, paired, match_data1, match_data2, -1, 1)
                            }}>&lt;</button> : <div></div>}
                            {Object.keys(match_data1)[currRenderOne.current]}
                            {currRenderOne.current < Object.keys(match_data1).length - 1 ? <button onClick={() => {
                                changeClusterRenderPaired(address, id1, id2, id, paired, match_data1, match_data2, 1, 1)
                            }}>&gt;</button> : <div></div>}
                            <div style={{"border": "thin solid", "borderRadius": "8px"}}>
                                <ClusterRender address={address} cID={id1} paired={paired} data={match_data1}
                                               dataSec={match_data2} currRender={currRenderOne.current}
                                               currRenderSec={currRenderTwo.current} id={id} width={450} height={450} toggleForceSliderHidden={toggleForceSliderHidden}/>
                            </div>
                        </div>
                        <div>
                            {currRenderTwo.current > 0 ? <button onClick={() => {
                                changeClusterRenderPaired(address, id1, id2, id, paired, match_data1, match_data2, -1, 2)
                            }}>&lt;</button> : <div></div>}
                            {Object.keys(match_data2)[currRenderTwo.current]}
                            {currRenderTwo.current < Object.keys(match_data2).length - 1 ? <button onClick={() => {
                                changeClusterRenderPaired(address, id1, id2, id, paired, match_data1, match_data2, 1, 2)
                            }}>&gt;</button> : <div></div>}
                            <div style={{"border": "thin solid", "borderRadius": "8px"}}>
                                <ClusterRender address={address} cID={id2} paired={paired} data={match_data2}
                                               dataSec={match_data1} currRender={currRenderTwo.current}
                                               currRenderSec={currRenderOne.current} id={id} width={450} height={450} toggleForceSliderHidden={toggleForceSliderHidden}/>
                            </div>
                        </div>
                    </Box>
                    <Box display={"flex"} flexDirection={"row"} justifyContent={"center"}>
                        <label>Toggle Unique Gene Coloring</label>
                        <input type={"checkbox"} onChange={(e) => {
                            uniqueGeneColorToggled.current = e.target.checked
                            setClusterRenderPaired(address, id1, id2, id, !e.target.checked, match_data1, match_data2)
                        }}></input>
                    </Box>
                    <Box display={"flex"} flexDirection={"row"} justifyContent={"center"}>
                        <label>Toggle Force Slider</label>
                        <input type={"checkbox"} checked={toggleForceSliderHidden.current} onChange={(e) => {
                            toggleForceSliderHidden.current = e.target.checked
                            setClusterRenderPaired(address, id1, id2, id, uniqueGeneColorToggled.current, match_data1, match_data2)
                        }}/>
                    </Box>
                </div>)
            } else {
                screenshotModalPaired(address, id1, id2, id, paired, match_data1, match_data2)
            }
        }

        function returnClusterKey() {

            let group1alias = props.configData.group1alias;
            let group2alias = props.configData.group2alias;
            if (group1alias !== "group1") {
                group1alias = group1alias
            }
            if (group2alias !== "group2") {
                group2alias = group2alias
            }


            let clusterKey = <Box display={"flex"} flexDirection={"column"} alignSelf={"center"}>
                <div style={clusterDiagramKeyStyle("group1")}>{group1alias}</div>
                {group2alias !== undefined && <div style={clusterDiagramKeyStyle("group2")}>{group2alias}</div>}
                {group2alias !== undefined && <div style={clusterDiagramKeyStyle("both")}>both</div>}
                <div style={clusterDiagramKeyStyle("imputed")}>imputed</div>
                <img src={key} width={150}/>
            </Box>

            return clusterKey
        }

        function clusterDiagramKeyStyle(group) {

            let gradient = ""
            if (group === "group1") {
                gradient = "linear-gradient(to right, rgba(255,200,0,0.2), rgba(255,200,0,0.8))" //yellow
            } else if (group === "group2") {
                gradient = "linear-gradient(to right, rgba(0,0,255,0.2), rgba(0,0,255,0.8))" //blue
            } else if (group === "both") {
                gradient = "linear-gradient(to right, rgba(0,255,0,0.2), rgba(0,255,0,0.8))" //green
            } else if (group === "imputed") {
                gradient = "linear-gradient(to right, rgba(255,0,0,0.2), rgba(255,0,0,0.8))" //red
            }

            let styles = {"backgroundImage":gradient, "width":"125px", "height":"25px", "margin": "10px", "border" : "thin solid"}

            return styles
        }

        function screenshotModalSingle(address, cID, id, new_data) {

            console.log(props)
            setModalData(<div>
                <div ref={modalRef}>
                        <Box id={"pairedBox"} display={"flex"} flexDirection={"row"} justifyContent={"center"}>
                    {/*style={{"margin": "auto"}}*/}
                    <div>
                        <h4>{`Project Name: ${props.projectName} `}</h4>

                        <div style={{"display":"flex", "flexDirection":"row", "justifyContent":"center"}}>
                            <h4>{`${cID} ${groupAlias(Object.keys(new_data)[currRenderOne.current])}`}</h4>
                        </div>
                        <div style={{"border": "thin solid", "borderRadius": "8px"}}>
                            <ClusterRender address={address} data={new_data} paired={paired} currRender={currRenderOne.current} id={id} width={500} height={450} toggleForceSliderHidden={toggleForceSliderHidden}/>
                        </div>
                    </div>

                    {returnClusterKey()}
                </Box>
                    <Box height={"20px"}></Box>
            </div>
                <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} width={"50%"} style={{margin: '0 auto'}}>
                    {/*{currRenderOne.current > 0  && <button style={{height: '40px'}} onClick={() => {changeClusterRenderSingle(address, cID, id, new_data, -1)}}>View Previous Network Image</button> }*/}

                    {currRenderOne.current > 0 ? <button style={{height: '40px'}} onClick={() => {changeClusterRenderSingle(address, cID, id, new_data, -1)}}>View Previous Network Image</button> : <button style={{height: '40px'}}>View Previous Network Image</button>}
                    <Box display={"flex"} flexDirection={"column"} justifyContent={"center"}>
                    <Box display={"flex"} flexDirection={"row"} justifyContent={"center"}>
                        <label>Toggle Force Slider</label>
                        <input type={"checkbox"} checked={toggleForceSliderHidden.current} onChange={(e) => {
                            toggleForceSliderHidden.current = e.target.checked
                            setClusterRenderSingle(address, cID, id, new_data)
                        }}/>
                    </Box>
                {/*{currRenderOne.current > 0 ? <button onClick={() => {changeClusterRenderSingle(address, cID, id, new_data, -1)}}>View Previous Network Image</button> : <div></div>}*/}
                <button onClick={() => {
                    filePath(address, id, cID).then((foundLink) => {
                        //TODO: edge case handling? should never occur tho
                        try {saveAs(foundLink,foundLink.substring(foundLink.lastIndexOf("\\")+1)) }
                        catch { alert("CLUSTER NOT LOADED YET") }
                    })
                }}>Save Cluster Data</button>
                <button onClick={() => {
                    const handleDownloadImage = async (printRef) => {
                        const element = printRef.current;
                        const canvas = await html2canvas(element);

                        const data = canvas.toDataURL('image/jpg');
                        const link = document.createElement('a');

                        console.log(props.configData)
                        console.log(new_data)
                        console.log(currRenderOne.current)

                        if (typeof link.download === 'string') {
                            link.href = data;
                            link.download = `${props.configData["title"]}_${cID}_${groupAlias(Object.keys(new_data)[currRenderOne.current])}.jpg`;

                            document.body.appendChild(link);
                            link.click();
                            //document.body.removeChild(link);
                        } else {
                            window.open(data);
                        }
                    };
                    handleDownloadImage(modalRef)
                }}>Save Image</button>
                    </Box>
                    {currRenderOne.current < Object.keys(new_data).length - 1 ? <button style={{height: '40px'}} onClick={() => {changeClusterRenderSingle(address, cID, id, new_data, 1)}}>View Next Network Image</button> : <button style={{height: '40px'}}>View Next Network Image</button>}
                    {/*{currRenderOne.current < Object.keys(new_data).length - 1 && <button style={{height: '40px'}} onClick={() => {changeClusterRenderSingle(address, cID, id, new_data, 1)}}>View Next Network Image</button>}*/}


                </Box>

                {/*{currRenderOne.current < Object.keys(new_data).length - 1 ? <button onClick={() => {changeClusterRenderSingle(address, cID, id, new_data, 1)}}>View Next Network Image</button> : <div></div>}*/}
            </div>)


        }

        function screenshotModalPaired(address, id1, id2, id, paired, match_data1, match_data2) {
            console.log("CORRECT FUNCTION")
            console.log(paired)

            setModalData(<div>
                <Box id={"pairedBox"} display={"flex"} flexDirection={"column"}>
                    <Box id={"pairedBoxHeader"} display={"flex"} flexDirection={"row"} justifyContent={"center"} ref={modalRef}>
                        {/*style={{"margin": "auto"}}*/}
                        <div>
                            <div>
                            {/*<h1>{`${props.projectName} ${id1}<=>${id2} ${groupAlias(Object.keys(match_data1)[currRenderOne.current])}<=>${groupAlias(Object.keys(match_data2)[currRenderTwo.current])}`}</h1>*/}
                                <h4 style={{textAlign: "center", marginLeft: "-150px"}}>{`Project Name: ${props.projectName}`}</h4>
                                <h4 style={{textAlign: "center", marginLeft: "-150px"}}> {`${id1}<=>${id2}`}</h4>
                                <h4 style={{textAlign: "center", marginLeft: "-150px"}}>{`${groupAlias(Object.keys(match_data1)[currRenderOne.current])}<=>${groupAlias(Object.keys(match_data2)[currRenderTwo.current])}`}</h4>
                                <Box display={"flex"} flexDirection={"row"}>
                                    <div id={"renderOne"}>
                                        <div style={{"border": "thin solid", "borderRadius": "8px"}}>
                                            <ClusterRender address={address} cID={id1} paired={paired} data={match_data1}
                                                           dataSec={match_data2} currRender={currRenderOne.current}
                                                           currRenderSec={currRenderTwo.current} id={id} width={400} height={400} toggleForceSliderHidden={toggleForceSliderHidden}/>
                                        </div>
                                    </div>
                                    <div id={"renderTwo"}>
                                        <div style={{"border": "thin solid", "borderRadius": "8px"}}>
                                            <ClusterRender address={address} cID={id2} paired={paired} data={match_data2}
                                                           dataSec={match_data1} currRender={currRenderTwo.current}
                                                           currRenderSec={currRenderOne.current} id={id} width={400} height={400} toggleForceSliderHidden={toggleForceSliderHidden}/>
                                        </div>
                                    </div>
                                    {returnClusterKey()}
                                </Box>
                            </div>
                            <Box height={"20px"}></Box>
                        </div>
                    </Box>
                    <Box id={"groupButtons"} display={"flex"} flexDirection={"row"} justifyContent={"center"} gridColumnGap={200} style={{textAlign: "center", marginLeft: "-150px"}}>
                        <Box>
                            {currRenderOne.current > 0 ? <button onClick={() => {
                                changeClusterRenderPaired(address, id1, id2, id, paired, match_data1, match_data2, -1, 1)
                            }}>&lt;</button> : <div></div>}
                            {Object.keys(match_data1)[currRenderOne.current]}
                            {currRenderOne.current < Object.keys(match_data1).length - 1 ? <button onClick={() => {
                                changeClusterRenderPaired(address, id1, id2, id, paired, match_data1, match_data2, 1, 1)
                            }}>&gt;</button> : <div></div>}
                        </Box>
                        <Box>
                            {currRenderTwo.current > 0 ? <button onClick={() => {
                                changeClusterRenderPaired(address, id1, id2, id, paired, match_data1, match_data2, -1, 2)
                            }}>&lt;</button> : <div></div>}
                            {Object.keys(match_data2)[currRenderTwo.current]}
                            {currRenderTwo.current < Object.keys(match_data2).length - 1 ? <button onClick={() => {
                                changeClusterRenderPaired(address, id1, id2, id, paired, match_data1, match_data2, 1, 2)
                            }}>&gt;</button> : <div></div>}
                        </Box>
                    </Box>
                </Box>

                <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} style={{textAlign: "center", marginLeft: "-150px"}}>
                    <Box display={"flex"} flexDirection={"row"} justifyContent={"center"}>
                        <label>Toggle Unique Gene Coloring</label>
                        <input type={"checkbox"} checked={uniqueGeneColorToggled.current} onChange={(e) => {
                            uniqueGeneColorToggled.current = e.target.checked
                            console.log("Modal should change?")
                            setClusterRenderPaired(address, id1, id2, id, uniqueGeneColorToggled.current, match_data1, match_data2)
                        }}/>
                    </Box>
                    <Box display={"flex"} flexDirection={"row"} justifyContent={"center"}>
                        <label>Toggle Force Slider</label>
                        <input type={"checkbox"} checked={toggleForceSliderHidden.current} onChange={(e) => {
                            toggleForceSliderHidden.current = e.target.checked
                            console.log("Modal should change?")
                            setClusterRenderPaired(address, id1, id2, id, uniqueGeneColorToggled.current, match_data1, match_data2)
                        }}/>
                    </Box>
                    <Box display={"flex"} flexDirection={"row"} justifyContent={"center"}>
                        <button onClick={() => {
                        filePath(address, id, cID).then((foundLink) => {
                            //TODO: edge case handling? should never occur tho
                            try {saveAs(foundLink,foundLink.substring(foundLink.lastIndexOf("\\")+1)) }
                            catch { alert("CLUSTER NOT LOADED YET") }
                        })
                    }}>Save Cluster Data</button>
                        <button onClick={() => {

                            const handleDownloadImage = async (printRef) => {
                                const element = printRef.current;
                                const canvas = await html2canvas(element);

                                const data = canvas.toDataURL('image/jpg');
                                const link = document.createElement('a');

                                console.log(props.configData)
                                //console.log(new_data)
                                //console.log(currRenderOne.current)

                                if (typeof link.download === 'string') {
                                    link.href = data;
                                    link.download = `${props.projectName}_${id1}<=>${id2}_${groupAlias(Object.keys(match_data1)[currRenderOne.current])}<=>${groupAlias(Object.keys(match_data2)[currRenderTwo.current])}.jpg`

                                    document.body.appendChild(link);
                                    link.click();
                                    //document.body.removeChild(link);
                                } else {
                                    window.open(data);
                                }
                            };

                            handleDownloadImage(modalRef)
                        }}>Save Image</button>
                    </Box>
                </Box>
            </div>)
        }

        function groupAlias(key) {

            let group1alias = props.configData.group1alias;
            let group2alias = props.configData.group2alias;

            console.log(group1alias);
            console.log(group2alias);

            if (group1alias === "group1") {
                group1alias = "Group1"
            }
            if (group2alias === "group2") {
                group2alias = "Group2"
            }

            let vals = {
                "Group1": group1alias,
                "Group1AndGroup2": group1alias + "And" + group2alias,
                "Group1MinusGroup2": group1alias + "Minus" + group2alias,
                "Group2": group2alias,
                "Group2MinusGroup1": group2alias + "Minus" + group1alias
            }

            if (key in vals) {
                return vals[key]
            }
        }
    }

    /*
    function handleClusterButtonClick(id, address, cID) {
        console.log("Sensing data")
        //props.addClusterButton(currClicked.current[0])
        //sendRenderCluster(currClicked[0])
        //console.log(props)

        console.log("Sensing data")
        //console.log(currClicked.current)
        //console.log(currClicked.current[0])
        if (addClusterButton(cID)) {
            let split = cID.split('-')
            let id1 = split[0].substring(0, split[0].length - 1)
            let id2 = split[1].substring(1, split[1].length)
            //getClusterLinksMap(address, id, cID)
        } else {
            console.log("Cluster already opened")
        }
        //console.log("CUR CLICK: " + currClicked.current[0])
    }
     */

    useEffect(() => {
        console.log(buttonList.current)
        setClusterChanged(false)
    }, [clusterChanged]);


    async function clusterSaveCommand(address, ID, saveText, deleteText) {
        const toSend = {save: saveText, delete: deleteText};
        let configJSON = {
            headers: {
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*',
            }
        }

        let res = await axios.post(
            address + "saveCluster/" + ID,
            toSend,
            configJSON)

        console.log(res)

        return res["data"]
    }

    // Check to make sure this only runs once
    useEffect(() => {
        console.log("props from layout")
        console.log(props)


        // seems like this is for persistence. doubt it'll help w single sample.
        clusterSaveCommand(props.address, props.id, "", "").then(res => {
            console.log("res is")
            console.log(res["output"])

            let clusterList = res["output"]
            for (let i in clusterList) {
                let cID = clusterList[i]

                if (cID !== "") {
                    addClusterButton(cID)
                    //focusCluster(props.id, props.address, cID)
                }
            }
            if (clusterList.length > 1) {
                focusCluster(props.id, props.address, clusterList[clusterList.length - 1])
            }
        })
    }, []);

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        console.log("modal open")
        console.log(props.address)
        console.log(idUsed)
        console.log(props.id)
        console.log(paired)
        showClusterDiagrams(props.address, idUsed, props.id, idUsed.includes("="))
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        screenshotModalView.current = false
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1300,
        height: 700,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        outline: 0,
        textAlign: "center"
    };

    //console.log(handleClusterClickCurry(props.id, props.address))
    //<ClusterBox getData={getData} paired={paired} cID={idUsed} boxTitle={"Cluster"} id={props.id} address={props.address} boxHeightOffset={0} content={infoDesc} curCID={idUsed} buttonList={buttonList}/>

    function chooseMarginTop(lst) {
        if (lst.length === 0) {
            return "0px";
        } else {
            return "30px";
        }
    }

    return (
        <div>
          <br></br>
          <br></br>
          <br></br>
            <ThemeProvider theme={theme}>
                {/* <Help show={showHelp} setShow={setShowHelp} theme={theme}/>
        <NavBar
          setShowHelp={setShowHelp}
        /> */}

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description">

                    <div>
                        <Box sx={modalStyle} alignItems={"center"}>
                            <IconButton className={classes.customizedButton} onClick={() => {handleClose()}}>
                                <Icon>{"close"}</Icon>
                            </IconButton>

                            {modalData}
                        </Box>
                    </div>
                </Modal>

                <Box textAlign={"center"}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <DendrogramBox boxTitle={"Dendrogram"}
                                       setPaired={setPaired}
                                       id={props.id}
                                       address={props.address}
                                       currClicked={currClicked}
                                       onClickFxn={handleClusterClickCurry(props.id, props.address)}
                                       boxHeightOffset={0}
                                       content={denDesc}
                                       addClusterButton={addClusterButton}
                                       jsonLink={props.jsonLink}
                                       configData={props.configData}/>
                    </Grid>
                    <Grid item xs={6} >
                        <Grid container xs={12} alignItems={"stretch"} direction={"column"} paddingRight={"30px"}>
                            <Box display={"flex"} gridGap={"5%"} flexWrap={"wrap"} marginTop={chooseMarginTop(buttonList.current)} justifyContent={"flex-start"} height={"30px"}>
                                {buttonList.current.map((e, index) =>
                                    <Box key={buttonList.current.length - index}>{e}</Box>)}
                            </Box>

                            <ClusterBox cID={idUsed}
                                        boxTitle={"Cluster"}
                                        id={props.id}
                                        address={props.address}
                                        boxHeightOffset={0}
                                        content={clusterData}
                                        curCID={idUsed}
                                        buttonList={buttonList}
                                        clusterExpand={handleOpen}
                                        screenshotModalView={screenshotModalView}
                                        setOpen={handleOpen}
                                        showClusterInfo={showClusterInfo}
                                        configData={props.configData}
                                        
                            />
                            <InfoBox boxTitle={"Combined Graph Info"}
                                     id={props.id}
                                     address={props.address}
                                     boxHeightOffset={300}
                                     content={infoDesc}
                                     descSet={setInfoDesc}
                                     curCID={idUsed}
                                     backendFxn={getInfoByCmd(props.id, props.address)}/>
                        </Grid>

                    </Grid>
                </Grid>
                </Box>

            </ThemeProvider>


        </div>
    );
}


export default FrontendLayout;
