import React from "react";
import PropTypes from "prop-types"
import styled from 'styled-components'


const Container = styled.div`
  progress[value] {
    width: ${props => props.width};
    appearance: none;

    ::-webkit-progress-bar {
      height: 10px;
      border-radius: 20px;
      background-color: #eee;
    }
    ::-webkit-progress-value {
      height: 10px;
      border-radius: 20px;
      background-color: ${props => props.color};
    }
  }
`;

const ProgressBar = props => {
  const {value, max, step, color,width, stepDetail} = props;

  return (
  <Container color={color} width={width}>
    <span>
      {/* <b>{(step == 0) ? "Loading Protein Interaction Graph: " : "Computing Pairwise Paths: "} </b> 
      {(step == 1) ? "(" + stepDetail + ")" : ""} */}
      <b>{stepDetail}</b>
    </span>
    <progress value={value} max={max}/>
    <span>{Math.round((value / max)*1000) / 10}%</span>
  </Container>);
};

ProgressBar.propTypes = {
  value : PropTypes.number.isRequired,
  max : PropTypes.number,
  step : PropTypes.number,
  color : PropTypes.string,
  width : PropTypes.string,
  stepDetail : PropTypes.string
};

ProgressBar.defaultProps = {
 max: 100,
 step: 0,
 color: '#ff7979',
 width: '150px',
 stepDetail: ''
};

export default ProgressBar;